import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useGlobalStore } from '@/hooks/useStore';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import LoadingRing from '@/components/common/LoadingRing';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANG } from '@/i18n/config';
import { EID_KEY, HMAC_KEY, UID_KEY } from '@/constants/app';

const withFormRequest = (WrappedComponent: () => JSX.Element): (() => JSX.Element) => {
  const Component = () => {
    const [loading, setLoading] = useState(true);
    const { getForm, enterAnswer, cookies } = useGlobalStore();
    const { t } = useTranslation();

    const { serial }: { serial: string } = useParams();

    const params: Record<string, string> = {};
    for (const [key, value] of new URLSearchParams(document.location.search)) {
      params[key.toLowerCase()] = value;
    }
    const uid = params[UID_KEY] ?? params[EID_KEY] ?? null;
    const hmac = params[HMAC_KEY] ?? null;

    useEffect(() => {
      const init = async () => {
        try {
          const result = await enterAnswer({ serial, uid, hmac });
          if (result) {
            await getForm(serial);
          }
        } catch (e) {
          alert(
            cookies.get('lang') === DEFAULT_LANG
              ? t('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.')
              : 'An error has occurred. Please try again in a moment.'
          );
          throw e;
        }
        setLoading(false);
      };

      void init();
    }, []);

    if (loading) {
      return (
        <LoadingContainer>
          <LoadingRing color="#fb5146" />
        </LoadingContainer>
      );
    }

    return (
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          background-color: #ffffff;
          background-repeat: no-repeat;
        `}
      >
        <WrappedComponent />
      </div>
    );
  };

  return observer(Component);
};

export default withFormRequest;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
