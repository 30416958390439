import React from 'react';
import { css } from '@emotion/react';
import see_you from '@/images/see_you.png';
import { useTranslation } from 'react-i18next';
import { useInitLanguageFromSearchParam } from '../hooks/useInitLanguageFromSearchParam';
import { Message } from './styled';

const Stopped = (): JSX.Element => {
  const { t } = useTranslation();

  const { isLoading } = useInitLanguageFromSearchParam();

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
      `}
    >
      <div
        css={css`
          margin: auto;
          text-align: center;
          img {
            width: 100%;
            max-width: 480px;
          }
        `}
      >
        <img src={see_you} alt="수집 완료" />
        <Message fontWeight={700} margin="40px 0 0 0" color="#1d252d">
          {!isLoading && t('수집 중인 설문이 아닙니다.')}
        </Message>
      </div>
    </div>
  );
};

export default Stopped;
