import styled from '@emotion/styled';
import React from 'react';

type Props = {
  width?: string;
  height?: string;
  color?: string;
};

const LoadingRing = ({ width = '48px', height = '48px', color = '#626ffc' }: Props): JSX.Element => {
  return (
    <Loading width={width} height={height} color={color}>
      <div />
    </Loading>
  );
};

export default LoadingRing;

const Loading = styled.div<{ width: string; height: string; color: string }>`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border: 6px solid ${({ color }) => color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
