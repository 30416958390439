import React from 'react';
import { observer } from 'mobx-react';
import { QuestionProps } from 'Models/Question';
import { css, useTheme } from '@emotion/react';
import NpsModelProps from 'Models/NpsModel';
import EvalText from '../common/EvalText';
import { NpsCell, NpsTable, NpsTableWrapper, NpsWrapper } from '@opensurvey/open-ui';

interface NpsProps {
  question: QuestionProps;
}

const Nps = ({ question }: NpsProps): JSX.Element => {
  const { color } = useTheme();
  const { concreteQuestion } = question;
  if (!concreteQuestion) {
    return <div />;
  }

  const { evaluationDisplayOptions, changeSelection, selectedIndex } = concreteQuestion as NpsModelProps;
  const { evalTexts } = evaluationDisplayOptions;

  const nps = [];
  for (let i = 0; i < 11; i += 1) {
    const dummy = {} as { checked?: boolean };
    nps.push(dummy);
  }

  return (
    <NpsWrapper>
      <NpsTableWrapper>
        <NpsTable>
          {nps.map((_, i) => {
            const isSelected = selectedIndex === i;
            return (
              <NpsCell
                color={color}
                onClick={() => {
                  if (isSelected) {
                    return;
                  }
                  changeSelection(i);
                }}
                key={i}
                selected={isSelected}
              >
                {i}
              </NpsCell>
            );
          })}
        </NpsTable>
      </NpsTableWrapper>
      {evalTexts && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: rgba(29, 37, 45, 0.6);
            > * {
              flex: 1;
            }
          `}
        >
          <EvalText text={evalTexts[0]} align="start" />
          <EvalText text={evalTexts[1]} align="end" />
        </div>
      )}
    </NpsWrapper>
  );
};

export default observer(Nps);
