import { css, useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { LikertModelProps } from 'Models/LikertModel';
import { QuestionProps } from 'Models/Question';
import React from 'react';
import EvalText from './EvalText';
import {
  ChoiceOption,
  VerticalLikertWrapper,
  VerticalLikertBar,
  VerticalLikertLevel,
  HorizontalLikertWrapper,
} from '@opensurvey/open-ui';

interface LikertProps {
  question: QuestionProps;
}

const VerticalLikert = ({ question }: LikertProps) => {
  const { color } = useTheme();
  const { concreteQuestion } = question;
  if (!concreteQuestion) {
    return <div />;
  }

  const { evaluationDisplayOptions, evaluationAnswerOptions, changeSelection, selectedIndex } =
    concreteQuestion as LikertModelProps;
  const { evalTexts } = evaluationDisplayOptions;
  const { level } = evaluationAnswerOptions;

  const levels = [];
  for (let i = 1; i <= level; ++i) {
    levels.push(i);
  }

  return (
    <HorizontalLikertWrapper>
      <VerticalLikertWrapper>
        <VerticalLikertBar color={color} />
        {levels.map((val, index) => {
          const id = `likert_option_${val}`;
          return (
            <VerticalLikertLevel
              key={id}
              onClick={() => {
                changeSelection(val);
              }}
              css={css`
                cursor: pointer;
              `}
            >
              <ChoiceOption tabIndex={index + 1} color={color} selected={selectedIndex === val} />
              {evalTexts && (
                <EvalText
                  text={evalTexts[index] || ''}
                  align="start"
                  customCss={css`
                    position: absolute;
                    @media screen and (min-width: 415px) {
                      left: 61px;
                      width: 400px;
                      right: 0;
                      padding: 10px 0;
                    }
                    @media screen and (max-width: 414px) {
                      width: 300px;
                      padding: 8px 0;
                      right: 0;
                      left: 42px;
                    }
                  `}
                />
              )}
            </VerticalLikertLevel>
          );
        })}
      </VerticalLikertWrapper>
    </HorizontalLikertWrapper>
  );
};

export default observer(VerticalLikert);
