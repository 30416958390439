import MobileOptOut from '@/components/opt-out/MobileOptOut';
import DesktopOptOut from '@/components/opt-out/DesktopOptOut';
import { FEEDBACK_HOMEPAGE_URL } from '@/constants/urls';
import { patchOptOutRequest } from '@/lib/ApiUtil';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';

const OptOut = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 414px)' });
  const history = useHistory();

  const [hasOptedOut, setHasOptedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const optOut = async (): Promise<void> => {
    const token = queryParams.get('t') || queryParams.get('token');
    if (!token) {
      history.push('/invalid');
      return;
    }
    setIsLoading(true);
    try {
      await patchOptOutRequest({
        token: token,
        optOut: true,
      });
      setHasOptedOut(true);
    } catch (e) {
      history.push('/invalid');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnCancel = (): void => {
    window.location.href = FEEDBACK_HOMEPAGE_URL;
  };

  return isMobile ? (
    <MobileOptOut onCancel={handleOnCancel} onConfirm={optOut} hasOptedOut={hasOptedOut} isLoading={isLoading} />
  ) : (
    <DesktopOptOut onCancel={handleOnCancel} onConfirm={optOut} hasOptedOut={hasOptedOut} isLoading={isLoading} />
  );
};

export default OptOut;
