import { observable, action, computed, makeObservable } from 'mobx';
import QuestionSchema, { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

export interface EssayModelProps {
  changeText: (text: string) => void;
  resetAnswer: () => void;
  text: string;
  maxLength: number;
  minLength: number;
  isAnswerValid: boolean;
  answer?: AnswerSchema[];
}

class EssayModel implements EssayModelProps {
  text = '';

  maxLength;

  minLength;

  constructor(question: QuestionSchema, questionAnswer?: QuestionAnswerSchema) {
    this.maxLength = question.answerOptions?.textAnswerOptions?.maxLength || 500;
    this.minLength = question.answerOptions?.textAnswerOptions?.minLength || 1;
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.text = answer[0].str;
      }
    }
    makeObservable(this, {
      text: observable,

      changeText: action,
      resetAnswer: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeText = (text: string): void => {
    const { maxLength } = this;
    if (maxLength) {
      if (text.length <= maxLength) {
        this.text = text;
      }
    } else {
      this.text = text;
    }
  };

  resetAnswer = (): void => {
    this.text = '';
  };

  get answer(): AnswerSchema[] | undefined {
    const text = this.text.trim();
    if (!text) return;
    return [{ str: text }];
  }

  get isAnswerValid(): boolean {
    const { maxLength, minLength, answer } = this;
    const text = answer?.[0].str || '';
    return minLength && maxLength ? minLength <= text.length && text.length <= maxLength : text.length > 0;
  }
}

export default EssayModel;
