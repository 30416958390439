import { PhoneRegex } from '@/constants/app';
import { observable, action, computed, makeObservable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

export interface PrivacyPolicyModelProps {
  changePhoneNumber: (text: string) => void;
  setAgreedToPrivacyPolicy: (value: boolean) => void;
  resetAnswer: () => void;
  phoneNumber: string;
  agreedToPrivacyPolicy?: boolean;

  isPhoneNumberValid: boolean;
  answer?: AnswerSchema[];
  isAnswerValid?: boolean; // 개인정보 문항의 canContinueWithoutAgreement 필드는 styleOptions 에 있기에 observable 로 처리
  setIsAnswerValid: (value: boolean) => void;
}

class PrivacyPolicyModel {
  phoneNumber = '';

  agreedToPrivacyPolicy;

  isAnswerValid = false;

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0) {
        this.setIsAnswerValid(true);
        this.agreedToPrivacyPolicy = answer[0].num ? true : false;
        if (answer[0].str) {
          this.phoneNumber = answer[0].str;
        }
      }
    }
    makeObservable(this, {
      phoneNumber: observable,
      agreedToPrivacyPolicy: observable,
      isAnswerValid: observable,

      resetAnswer: action,
      setAgreedToPrivacyPolicy: action,
      changePhoneNumber: action,
      setIsAnswerValid: action,

      isPhoneNumberValid: computed,
      answer: computed,
    });
  }

  resetAnswer = (): void => {
    this.agreedToPrivacyPolicy = undefined as unknown as boolean;
    this.phoneNumber = '';
  };

  setAgreedToPrivacyPolicy = (value: boolean): void => {
    this.agreedToPrivacyPolicy = value;
  };

  changePhoneNumber = (value: string): void => {
    this.phoneNumber = value;
  };

  setIsAnswerValid = (value: boolean): void => {
    this.isAnswerValid = value;
  };

  get isPhoneNumberValid(): boolean {
    return PhoneRegex.test(this.phoneNumber);
  }

  get answer(): Array<{ str?: string; num?: number }> | undefined {
    if (!this.isAnswerValid) return undefined;
    if (!this.phoneNumber) return [{ num: this.agreedToPrivacyPolicy ? 1 : 0 }];
    return [{ num: this.agreedToPrivacyPolicy ? 1 : 0, str: this.phoneNumber }];
  }
}

export default PrivacyPolicyModel;
