import { LOG_LEVEL } from '@/constants/app';
import { FormSchema } from '@/schemas/FormSchema';
import { AnswerSchema } from '@/schemas/QuestionSchema';
import axios, { AxiosResponse } from 'axios';
import { FEEDBACK_API_URL, FEEDBACK_I18N_URL } from 'Constants/urls';
import { CustomTypeOptions } from 'i18next';
import { stringify } from 'query-string';

export function getFormRequest(serial: string): Promise<AxiosResponse<FormSchema>> {
  return axios.get(`${FEEDBACK_API_URL}/api/v1/feedback/form?serial=${serial}`);
}

export function postAnswer(
  finish: string,
  body: {
    answerToken: string;
    questionNo: number;
    answer: AnswerSchema[];
  }
): Promise<AxiosResponse<void>> {
  return axios.post(`${FEEDBACK_API_URL}/api/v1/feedback/answer?finish=${finish}`, body);
}

export function postEnter(body: {
  serial: string;
  answerToken: string;
  uid: string | null;
  hmac: string | null;
}): Promise<
  AxiosResponse<{
    answerToken: string;
    expireAt: string;
    isSubmitted: boolean;
    formNo: number;
    answers: Array<{ questionNo: number; answer: Array<{ num: number; str: string }> }>;
    channelStatus: 'STOP' | 'RUNNING' | 'UNAVAILABLE';
    lang: string;
  }>
> {
  return axios.post(`${FEEDBACK_API_URL}/api/v2/feedback/enter`, body);
}

//TODO: getForm 함수와 통합 예정
export function getSharedForm(params: { formNo: number; previewToken: string }): Promise<AxiosResponse<FormSchema>> {
  return axios.get(`${FEEDBACK_API_URL}/api/v1/feedback/shared-form?${stringify(params)}`);
}

export function patchOptOutRequest(params: { token: string; optOut: boolean }): Promise<AxiosResponse<void>> {
  return axios.patch(`${FEEDBACK_API_URL}/api/v1/feedback/opt-out?${stringify(params)}`);
}

export function logError(message: string, level: LOG_LEVEL): Promise<AxiosResponse<void>> {
  return axios.post(`${FEEDBACK_API_URL}/front/v1/log?level=${level}`, message, {
    headers: { 'Content-Type': 'text/plain' },
  });
}

export async function getI18NData(lang: string): Promise<AxiosResponse<CustomTypeOptions['resources']['translation']>> {
  return axios.get(`${FEEDBACK_I18N_URL}/${lang}.json`).then((res) => res.data);
}
