import React from 'react';
import { css } from '@emotion/react';
import { hexToRgb } from '@/lib/Util';
import { useMediaQuery } from 'react-responsive';

type IconProps = React.SVGProps<SVGSVGElement> & { color?: string };

const CircleCheckbox = ({ color }: { color: string }): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" stroke={color || '#fb5146'} strokeOpacity={0.1} />
  </svg>
);

const ChevronRight = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 7L14.5 12L9.5 17"
      stroke="#1D252D"
      strokeOpacity="0.87"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronLeft = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 16.9995L9.5 11.9995L14.5 6.99951"
      stroke="#1D252D"
      strokeOpacity="0.87"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Calendar = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 16C8.94036 16 9.5 15.4404 9.5 14.75C9.5 14.0596 8.94036 13.5 8.25 13.5C7.55964 13.5 7 14.0596 7 14.75C7 15.4404 7.55964 16 8.25 16Z"
      fill="#777C81"
    />
    <path
      d="M12 16C12.6904 16 13.25 15.4404 13.25 14.75C13.25 14.0596 12.6904 13.5 12 13.5C11.3096 13.5 10.75 14.0596 10.75 14.75C10.75 15.4404 11.3096 16 12 16Z"
      fill="#777C81"
    />
    <path
      d="M15.75 16C16.4404 16 17 15.4404 17 14.75C17 14.0596 16.4404 13.5 15.75 13.5C15.0596 13.5 14.5 14.0596 14.5 14.75C14.5 15.4404 15.0596 16 15.75 16Z"
      fill="#777C81"
    />
    <path
      d="M20 5.5H17V4C17 3.7225 16.775 3.5 16.5 3.5H15.5C15.2225 3.5 15 3.725 15 4V5.5H9V4C9 3.7225 8.775 3.5 8.5 3.5H7.5C7.2225 3.5 7 3.725 7 4V5.5H4C3.725 5.5 3.5 5.725 3.5 6V20C3.5 20.275 3.725 20.5 4 20.5H20C20.275 20.5 20.5 20.275 20.5 20V6C20.5 5.725 20.275 5.5 20 5.5ZM18.5 7.5V9.5H5.5V7.5H18.5ZM5.5 18.5V11.5H18.5V18.5H5.5Z"
      fill="#777C81"
    />
  </svg>
);

const CircleCheckboxChecked = ({ color }: { color: string }): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" fill={hexToRgb(color || '#fb5146', 0.4)} stroke={color || '#fb5146'} />
    <circle cx="12" cy="12" r="5.5" fill={color || '#fb5146'} stroke={color || '#fb5146'} />
  </svg>
);

const Star = ({
  color,
  selected,
  shouldFillHover,
}: {
  color: string;
  selected: boolean;
  shouldFillHover: boolean;
}): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 414px)' });

  return isMobile ? (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        css={css`
          @media (hover: hover) and (pointer: fine) {
            fill: ${shouldFillHover && hexToRgb(color, 0.1)};
            &:hover {
              fill: ${shouldFillHover && hexToRgb(color, 0.1)};
            }
          }
        `}
        d="M18.5348 29.3039L18.0011 28.9662L17.4668 29.3029L8.73702 34.8056L8.73522 34.8067C8.32321 35.0677 7.8886 35.0133 7.55142 34.7567C7.20778 34.4952 6.99124 34.0354 7.11215 33.4914L9.42596 23.1028L9.55239 22.5352L9.12156 22.1446L1.40152 15.1453L1.40118 15.1449C0.579815 14.401 1.11271 13.1056 2.01181 13.0308L2.01181 13.0308L2.01734 13.0303L12.1775 12.1279L12.7859 12.0739L13.0156 11.5079L16.9913 1.71325L16.9928 1.70946C17.3723 0.763514 18.6277 0.763514 19.0072 1.70946L19.0084 1.71252L22.9841 11.5292L23.2136 12.0958L23.8225 12.1499L33.9827 13.0524L33.9882 13.0528C34.8873 13.1277 35.4202 14.423 34.5988 15.167L34.5985 15.1673L26.8784 22.1666L26.4476 22.5572L26.574 23.1248L28.8878 33.5134C29.0088 34.0574 28.7922 34.5172 28.4486 34.7787C28.1114 35.0353 27.6768 35.0897 27.2648 34.8287L27.2645 34.8286L18.5348 29.3039Z"
        fill={selected ? hexToRgb(color, 0.4) : 'white'}
        stroke={color}
        strokeWidth={selected || shouldFillHover ? 2 : 1}
      />
    </svg>
  ) : (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        css={css`
          @media (hover: hover) and (pointer: fine) {
            fill: ${shouldFillHover && hexToRgb(color, 0.1)};
            &:hover {
              fill: ${shouldFillHover && hexToRgb(color, 0.1)};
            }
          }
        `}
        d="M24.5348 39.3535L24.0011 39.0158L23.4668 39.3526L11.8271 46.6894L11.8253 46.6906C11.1468 47.1202 10.416 47.0255 9.8667 46.6075C9.31104 46.1847 8.96656 45.4426 9.15738 44.5833C9.15741 44.5831 9.15744 44.583 9.15747 44.5828L12.2426 30.7313L12.369 30.1637L11.9382 29.7731L1.6448 20.4406L1.64446 20.4403C0.353303 19.2708 1.13862 17.1684 2.65477 17.0422L2.65477 17.0422L2.6603 17.0417L16.2072 15.8385L16.8156 15.7845L17.0453 15.2185L22.3462 2.15896L22.3477 2.15517C22.9656 0.614943 25.0344 0.614943 25.6522 2.15517L25.6535 2.15823L30.9544 15.2472L31.1839 15.8138L31.7928 15.8679L45.3397 17.0711L45.3452 17.0716C46.8614 17.1978 47.6467 19.3001 46.3555 20.4697L46.3552 20.47L36.0618 29.8024L35.631 30.193L35.7574 30.7607L38.8425 44.6122C39.0336 45.4717 38.689 46.214 38.1333 46.6369C37.584 47.0549 36.8532 47.1496 36.1747 46.7199L36.1744 46.7197L24.5348 39.3535Z"
        fill={selected ? hexToRgb(color, 0.4) : 'white'}
        stroke={color}
        strokeWidth={selected || shouldFillHover ? 2 : 1}
      />
    </svg>
  );
};

const MultipleCircleChecked = ({ color }: { color: string }): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" fill={hexToRgb(color || '#fb5146', 0.4)} stroke={color || '#fb5146'} />
    <path
      d="M7 10.8462L11.0909 15L17 9"
      stroke={color || '#fb5146'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Refresh = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4806 4.75192L15.5743 5.65817C14.2452 3.94775 12.1827 2.9165 9.98893 2.9165C6.08893 2.9165 2.91602 6.09359 2.91602 9.99984C2.91602 13.9061 6.08893 17.0832 9.98893 17.0832C12.7681 17.0832 15.3014 15.4415 16.4431 12.9019C16.5202 12.729 16.591 12.554 16.6556 12.3748C16.7348 12.1498 16.6056 11.904 16.3764 11.8373L15.5743 11.6061C15.3639 11.5457 15.1452 11.6582 15.0702 11.8644C15.0264 11.9832 14.9785 12.1019 14.9264 12.2186C14.0514 14.1623 12.1139 15.4165 9.98893 15.4165C7.0056 15.4165 4.5806 12.9873 4.5806 9.99984C4.5806 7.01234 7.00768 4.58317 9.98893 4.58317C11.7368 4.58317 13.3764 5.4415 14.3868 6.84984L13.4035 7.83525C13.2202 8.01859 13.3493 8.33317 13.6098 8.33317H16.5618C16.791 8.33317 16.9785 8.14567 16.9785 7.9165V4.95817C16.9785 4.69775 16.6639 4.56859 16.4806 4.75192Z"
      fill="#777C81"
    />
  </svg>
);

export const CircleClose = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        :hover {
          circle {
            fill: #b0b3b5;
          }
        }
      `}
    >
      <circle cx="12" cy="12" r="8" fill="#D2D3D5" />
      <path
        d="M15.883 14.7513L13.1317 12L15.883 9.24869C16.039 9.09273 16.039 8.83879 15.883 8.68283L15.3172 8.11697C15.1612 7.96101 14.9073 7.96101 14.7513 8.11697L12 10.8683L9.24869 8.11697C9.09273 7.96101 8.83879 7.96101 8.68283 8.11697L8.11697 8.68283C7.96101 8.83879 7.96101 9.09273 8.11697 9.24869L10.8683 12L8.11897 14.7493C7.96301 14.9053 7.96301 15.1592 8.11897 15.3152L8.68483 15.881C8.84079 16.037 9.09473 16.037 9.25069 15.881L12 13.1297L14.7513 15.881C14.9073 16.037 15.1612 16.037 15.3172 15.881L15.883 15.3152C16.039 15.1592 16.039 14.9073 15.883 14.7513Z"
        fill="white"
      />
    </svg>
  );
};

export const Image = (props: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.5 4H3.5C3.225 4 3 4.225 3 4.5V19.5C3 19.775 3.225 20 3.5 20H20.5C20.775 20 21 19.775 21 19.5V4.5C21 4.225 20.775 4 20.5 4ZM19 18H5V17.915L8.25 14.665L9.7925 16.2075C10.1825 16.5975 10.8175 16.5975 11.2075 16.2075L15 12.415L19 16.415V18ZM15.7075 10.2925C15.3175 9.9025 14.6825 9.9025 14.2925 10.2925L10.5 14.085L8.9575 12.5425C8.5675 12.1525 7.9325 12.1525 7.5425 12.5425L5 15.085V6H19V13.585L15.7075 10.2925Z"
      fill="currentColor"
    />
    <path
      d="M9.38741 8.2904C8.71991 7.8004 7.77991 7.9454 7.28991 8.6129C6.79991 9.2804 6.94491 10.2204 7.61241 10.7104C8.27991 11.2004 9.21991 11.0554 9.70991 10.3879C10.1999 9.7179 10.0549 8.7804 9.38741 8.2904Z"
      fill="currentColor"
    />
  </svg>
);

export const Search = (props: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_23478_105826)">
      <path
        d="M19.8518 18.4397L16.6018 15.1897C16.6168 15.1722 16.6318 15.1547 16.6443 15.1372C17.7493 13.6297 18.2018 11.7822 17.9168 9.93475C17.6318 8.08475 16.6443 6.45975 15.1368 5.35475C13.6293 4.24975 11.7793 3.79725 9.9293 4.08225C9.0068 4.22475 8.1368 4.54225 7.3618 5.01475C6.5868 5.48475 5.9043 6.10975 5.3518 6.86225C3.0693 9.97475 3.7468 14.3647 6.8593 16.6447C8.0718 17.5347 9.5043 17.9997 10.9818 17.9997C11.3393 17.9997 11.7018 17.9722 12.0618 17.9172C13.2143 17.7397 14.2843 17.2872 15.1918 16.6072L18.4368 19.8523C18.6318 20.0497 18.9468 20.0497 19.1418 19.8523L19.8493 19.1472C20.0493 18.9497 20.0493 18.6347 19.8518 18.4397ZM13.7543 15.1697C12.0593 16.2897 9.7743 16.3022 8.0418 15.0322C5.8168 13.4022 5.3343 10.2672 6.9643 8.04475C8.5943 5.82225 11.7318 5.33725 13.9543 6.96725C15.0318 7.75725 15.7368 8.91725 15.9393 10.2372C16.1318 11.4772 15.8593 12.7172 15.1693 13.7572C15.1243 13.8247 15.0793 13.8897 15.0318 13.9547C14.6718 14.4472 14.2368 14.8547 13.7543 15.1697Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_23478_105826">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseSmall = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="16.9492"
      y="5.63574"
      width="2"
      height="16"
      rx="1"
      transform="rotate(45 16.9492 5.63574)"
      fill="currentColor"
    />
    <rect
      x="18.3633"
      y="16.9502"
      width="2"
      height="16"
      rx="1"
      transform="rotate(135 18.3633 16.9502)"
      fill="currentColor"
    />
  </svg>
);

export {
  CircleCheckbox,
  CircleCheckboxChecked,
  MultipleCircleChecked,
  ChevronLeft,
  ChevronRight,
  Calendar,
  Star,
  Refresh,
};
