import React, { useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { useAnswerStore } from '@/hooks/useStore';
import { CircleClose, CloseSmall, Search } from '@/constants/icons';
import { CustomTextInput, Dialog, OpenEndedWrapper } from '@opensurvey/open-ui';
import theme from '@/styled/theme';
import AddressModel from '@/models/AddressModel';
import DaumPostcodeEmbed, { Address as AddressType } from 'react-daum-postcode';
import styled from '@emotion/styled';

const Address = (): JSX.Element => {
  const { color } = useTheme();
  const { currentQuestion } = useAnswerStore();
  const { concreteQuestion } = currentQuestion as { concreteQuestion: AddressModel };

  const { address, isAnswerValid, changeAddress } = concreteQuestion;
  const [isDaumPostOpen, setIsDaumPostOpen] = useState(false);
  const detailedAddressInputRef = useRef<HTMLInputElement>(null);

  const handleComplete = (data: AddressType) => {
    changeAddress({
      postalCode: data.zonecode,
      roadAddress: `${data.autoRoadAddress || data.roadAddress} ${data.buildingName && `(${data.buildingName})`}`,
    });

    setIsDaumPostOpen(false);
    detailedAddressInputRef.current?.focus();
  };

  const handleClick = () => {
    setIsDaumPostOpen(true);
  };

  return (
    <OpenEndedWrapper>
      <CustomTextInput
        borderColor={color}
        placeholder="우편번호 검색"
        value={address.postalCode}
        Icon={<Search cursor="pointer" color={theme.colors.blackIcon.black_4} onClick={handleClick} />}
        onFocus={handleClick}
        readOnly
      />
      <CustomTextInput
        borderColor={color}
        placeholder="도로명 / 지번 주소"
        value={address.roadAddress}
        disabled
        margin="16px 0"
      />
      <CustomTextInput
        ref={detailedAddressInputRef}
        borderColor={color}
        placeholder="상세 주소 입력"
        value={address.detailedAddress}
        Icon={address.detailedAddress.length > 0 ? <CircleClose /> : null}
        onIconClick={() => {
          changeAddress({
            detailedAddress: '',
          });
        }}
        helperMessage="주소를 입력해주세요."
        warningMessage="주소를 입력해주세요."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          changeAddress({
            detailedAddress: value,
          });
        }}
        disabled={!(address.postalCode || address.roadAddress)}
        isValid={isAnswerValid}
      />

      <Dialog
        width={400}
        open={isDaumPostOpen}
        onClose={() => {
          setIsDaumPostOpen(false);
        }}
        disableBackdropClick
        height={100}
      >
        <DialogChild>
          <CloseButtonWrapper>
            <CloseButton
              onClick={() => {
                setIsDaumPostOpen(false);
              }}
            >
              <CloseSmall />
            </CloseButton>
          </CloseButtonWrapper>
          <StyledPostCodeEmbed onComplete={handleComplete} defaultQuery={address.roadAddress} />
        </DialogChild>
      </Dialog>
    </OpenEndedWrapper>
  );
};

export default observer(Address);

const StyledPostCodeEmbed = styled(DaumPostcodeEmbed)`
  min-height: 100%;
  max-height: 90vh;
`;

const DialogChild = styled.div`
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  height: 100%;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
`;

const CloseButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  color: ${theme.colors.blackIcon.black_2};
  :hover {
    color: ${theme.colors.blackIcon.black_1};
  }
`;
