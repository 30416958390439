import React from 'react';
import { observer } from 'mobx-react';
import { QuestionProps } from 'Models/Question';
import { css, useTheme } from '@emotion/react';
import EvalText from '../common/EvalText';
import { NpsCell, NpsTable, NpsTableWrapper, NpsWrapper } from '@opensurvey/open-ui';
import { LikertModelProps } from '@/models';

interface Props {
  question: QuestionProps;
}

const HorizontalLikert10 = ({ question }: Props): JSX.Element => {
  const { color } = useTheme();
  const { concreteQuestion } = question;
  if (!concreteQuestion) {
    return <div />;
  }

  const {
    evaluationDisplayOptions,
    evaluationAnswerOptions: { level },
    changeSelection,
    selectedIndex,
  } = concreteQuestion as LikertModelProps;
  const { evalTexts } = evaluationDisplayOptions;

  const likert = [];
  for (let i = 1; i <= level; i += 1) {
    likert.push(i);
  }

  return (
    <NpsWrapper level={level}>
      <NpsTableWrapper>
        <NpsTable>
          {likert.map((val) => {
            const isSelected = selectedIndex === val;
            return (
              <NpsCell
                color={color}
                onClick={() => {
                  if (isSelected) {
                    return;
                  }
                  changeSelection(val);
                }}
                key={val}
                selected={isSelected}
              >
                {val}
              </NpsCell>
            );
          })}
        </NpsTable>
      </NpsTableWrapper>
      {evalTexts && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: rgba(29, 37, 45, 0.6);
            > * {
              flex: 1;
            }
          `}
        >
          <EvalText text={evalTexts[0]} align="start" />
          <EvalText text={evalTexts[1]} align="end" />
        </div>
      )}
    </NpsWrapper>
  );
};

export default observer(HorizontalLikert10);
