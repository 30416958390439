import React from 'react';
import { css } from '@emotion/react';
import Option from './Option';
import { observer } from 'mobx-react';
import { useAnswerStore, useGlobalStore } from '@/hooks/useStore';
import { QUESTION_TYPE } from '@/constants/app';
import { QuestionDescription, QuestionTextWrapper, QuestionTitle } from '@opensurvey/open-ui';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'isomorphic-dompurify';

const Question = (): JSX.Element => {
  const globalStore = useGlobalStore();
  const answerStore = useAnswerStore();
  const { questions } = globalStore;
  const { currentQuestionIndex, currentQuestion } = answerStore;
  const { t } = useTranslation();

  const question = questions[currentQuestionIndex];

  if (!question) {
    return <div>{t('잘못된 설문입니다.')}</div>;
  }

  const questionTitle = question.replaceReferString(currentQuestionIndex, questions);

  return (
    <div
      css={css`
        color: #1d252d;
      `}
    >
      {currentQuestion.type !== QUESTION_TYPE.PRIVACY_POLICY && (
        <QuestionTextWrapper>
          <QuestionTitle dangerouslySetInnerHTML={{ __html: sanitize(questionTitle) }} />
          <QuestionDescription dangerouslySetInnerHTML={{ __html: sanitize(question.description) }} />
        </QuestionTextWrapper>
      )}
      <div>
        <Option question={currentQuestion} type={question.type} />
      </div>
    </div>
  );
};

export default observer(Question);
