import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './ko.json';

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const resources = {
  ko: {
    translation,
  },
};

export const DEFAULT_LANG = 'ko';

void i18n.use(initReactI18next).init({
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  debug: isLocal,
  resources,
});

export default i18n;
