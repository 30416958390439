import { observable, action, computed, makeObservable } from 'mobx';
import QuestionSchema, { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';
export interface ShortAnswerModelProps {
  textAnswerOptions: {
    minLength: number;
    maxLength: number;
    limitAnswer: number;
  };
  changeText: (text: string) => void;
  resetAnswer: () => void;
  text: string;
  isAnswerValid: boolean;
  answer?: AnswerSchema[];
}

class ShortAnswerModel implements ShortAnswerModelProps {
  text = '';

  textAnswerOptions = {} as { minLength: number; maxLength: number; limitAnswer: number };

  constructor(question: QuestionSchema, questionAnswer?: QuestionAnswerSchema) {
    const { answerOptions } = question;
    this.textAnswerOptions = answerOptions?.textAnswerOptions as {
      minLength: number;
      maxLength: number;
      limitAnswer: number;
    };
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.text = answer[0].str;
      }
    }
    makeObservable(this, {
      text: observable,

      resetAnswer: action,
      changeText: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  resetAnswer = (): void => {
    this.text = '';
  };

  changeText = (text: string): void => {
    const { textAnswerOptions } = this;
    if (textAnswerOptions.maxLength) {
      if (text.length <= textAnswerOptions.maxLength) {
        this.text = text;
      }
    } else {
      this.text = text;
    }
  };

  get answer(): AnswerSchema[] | undefined {
    const text = this.text.trim();
    if (!text) return;
    return [{ str: text }];
  }

  get isAnswerValid(): boolean {
    const {
      textAnswerOptions: { maxLength, minLength },
      answer,
    } = this;
    const text = answer?.[0].str || '';
    return minLength && maxLength ? minLength <= text.length && text.length <= maxLength : text.length > 0;
  }
}

export default ShortAnswerModel;
