import { EmailRegex } from '@/constants/app';
import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

class EmailModel {
  email = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.email = answer[0].str;
      }
    }
    makeObservable(this, {
      email: observable,

      changeEmail: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeEmail = (value: string): void => {
    this.email = value;
  };

  resetAnswer = (): void => {
    this.changeEmail('');
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.email) return;
    return [{ str: this.email }];
  }

  get isAnswerValid(): boolean {
    return EmailRegex.test(this.email);
  }
}

export default EmailModel;
