import React from 'react';
import { QUESTION_TYPE } from 'Constants/app';
import Choice from './Choice';
import Essay from './Essay';
import Nps from './Nps';
import Likert from './Likert';
import ShortAnswer from './ShortAnswer';
import { QuestionProps } from 'Models/Question';
import DateQuestion from './DateQuestion';
import Numeric from './Numeric';
import PrivacyPolicy from './PrivacyPolicy';
import StarScale from './StarScale';
import Phone from './Phone';
import Email from './Email';
import Address from './Address';
import Name from './Name';
import Birth from './Birth';
import PrivacyOther from './PrivacyOther';
interface OptionProps {
  type: number;
  question: QuestionProps;
}

const Option = ({ question, type }: OptionProps): JSX.Element => {
  switch (type) {
    case QUESTION_TYPE.SINGLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE:
    case QUESTION_TYPE.RANK:
      return <Choice question={question} />;
    case QUESTION_TYPE.TEXT:
      return <Essay question={question} />;
    case QUESTION_TYPE.TEXT_SHORT:
      return <ShortAnswer question={question} />;
    case QUESTION_TYPE.NPS:
      return <Nps question={question} />;
    case QUESTION_TYPE.EVALUATION:
      return <Likert question={question} />;
    case QUESTION_TYPE.STAR_SCALE:
      return <StarScale question={question} />;
    case QUESTION_TYPE.PRIVACY_POLICY:
      return <PrivacyPolicy question={question} />;
    case QUESTION_TYPE.TEXT_DATE:
      return <DateQuestion question={question} />;
    case QUESTION_TYPE.NUMERIC:
      return <Numeric question={question} />;
    case QUESTION_TYPE.PRIVACY_NAME:
      return <Name />;
    case QUESTION_TYPE.TEXT_PHONE:
      return <Phone />;
    case QUESTION_TYPE.TEXT_EMAIL:
      return <Email />;
    case QUESTION_TYPE.TEXT_ADDRESS:
      return <Address />;
    case QUESTION_TYPE.PRIVACY_BIRTH:
      return <Birth />;
    case QUESTION_TYPE.PRIVACY_OTHER:
      return <PrivacyOther />;
    default:
      return <div />;
  }
};

export default Option;
