import React from 'react';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { useAnswerStore } from '@/hooks/useStore';
import { CircleClose } from '@/constants/icons';
import { CustomTextInput, OpenEndedWrapper } from '@opensurvey/open-ui';
import BirthModel from '@/models/BirthModel';
import { useTranslation } from 'react-i18next';

const Birth = (): JSX.Element => {
  const { color } = useTheme();
  const { currentQuestion } = useAnswerStore();
  const { concreteQuestion } = currentQuestion as { concreteQuestion: BirthModel };
  const { birth, isAnswerValid, changeBirth, resetAnswer } = concreteQuestion;
  const { t } = useTranslation();

  const message = t('연/월/일을 입력해 주세요.');

  return (
    <OpenEndedWrapper>
      <CustomTextInput
        pattern="\d*"
        borderColor={color}
        placeholder={t('YYYY-MM-DD')}
        value={birth}
        Icon={birth.length > 0 ? <CircleClose /> : null}
        onIconClick={resetAnswer}
        helperMessage={message}
        warningMessage={message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          changeBirth(value);
        }}
        isValid={isAnswerValid}
      />
    </OpenEndedWrapper>
  );
};

export default observer(Birth);
