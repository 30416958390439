import {
  BrandingImage,
  BrandingImageWrapper,
  DescriptionWrapper,
  ImageWrapper,
  InOutroImage,
  InOutroWrapper,
  TitleWrapper,
  H4,
  S2,
} from '@opensurvey/open-ui';
import { INTRO_DEFAULT_TITLE, OUTRO_DEFAULT_TITLE } from '@/constants/app';
import { useGlobalStore } from '@/hooks/useStore';
import baseImage from '@/images/intro_outro_base_image.png';
import powered_by from '@/images/powered_by.png';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'isomorphic-dompurify';

type InOutroProps = {
  type: 'intro' | 'outro';
};

const InOutro = ({ type }: InOutroProps): JSX.Element => {
  const globalStore = useGlobalStore();
  const isMobile = useMediaQuery({ query: '(max-width: 414px)' });
  const {
    form: {
      styleOptions: { intro, outro },
    },
    questions,
  } = globalStore;
  const { t } = useTranslation();

  if (!(questions.length > 0)) {
    return <div>{t('잘못된 설문입니다.')}</div>;
  }

  const source = type === 'intro' ? intro : outro;
  const ResponsiveBrandingImageWrapper = isMobile ? BrandingImageWrapper : Fragment;

  return (
    <>
      <InOutroWrapper>
        <ImageWrapper>
          <ResponsiveBrandingImageWrapper>
            {source?.showBrandLogo && (
              <BrandingImage src={source?.brandLogoImage ? source?.brandLogoImage : powered_by} alt={'brandLogo'} />
            )}
          </ResponsiveBrandingImageWrapper>

          {source?.showImage && <InOutroImage src={source?.image ? source?.image : baseImage} alt="이미지" />}
        </ImageWrapper>
        <TitleWrapper>
          {type === 'intro' ? (
            intro?.title ? (
              <div dangerouslySetInnerHTML={{ __html: sanitize(intro?.title) }} />
            ) : isMobile ? (
              <S2 color="#1d252d" fontWeight={500}>
                {INTRO_DEFAULT_TITLE}
              </S2>
            ) : (
              <H4>{INTRO_DEFAULT_TITLE}</H4>
            )
          ) : outro?.title ? (
            <div dangerouslySetInnerHTML={{ __html: sanitize(outro?.title) }} />
          ) : isMobile ? (
            <S2 color="#1d252d" fontWeight={500}>
              {OUTRO_DEFAULT_TITLE}
            </S2>
          ) : (
            <H4>{OUTRO_DEFAULT_TITLE}</H4>
          )}
        </TitleWrapper>
        <DescriptionWrapper>
          {source?.description && <div dangerouslySetInnerHTML={{ __html: sanitize(source?.description) }} />}
        </DescriptionWrapper>
      </InOutroWrapper>
    </>
  );
};

export default observer(InOutro);
