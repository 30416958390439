import { DateRegex } from '@/constants/app';
import { Calendar, ChevronLeft, ChevronRight } from '@/constants/icons';
import { hexToRgb, toISOStringInKST, autoFormatDate } from '@/lib/Util';
import { DateModelProps } from '@/models/DateModel';
import { QuestionProps } from '@/models/Question';
import { css, useTheme } from '@emotion/react';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CustomTextInput, OpenEndedWrapper } from '@opensurvey/open-ui';
import { useTranslation } from 'react-i18next';
import theme from '@/styled/theme';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface DateQuestionProps {
  question: QuestionProps;
}

const DateQuestion = ({ question }: DateQuestionProps): JSX.Element => {
  const { color } = useTheme();
  const { concreteQuestion } = question as { concreteQuestion: DateModelProps };
  const { date, changeDate } = concreteQuestion;
  const { t } = useTranslation();

  const [toggle, setToggle] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    if (DateRegex.test(date)) {
      setCurrentMonth(parseISO(date));
    }
  }, [date]);

  const renderHeader = () => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding: 12px 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            font-size: 18px;
            span {
              color: ${hexToRgb(color, 1)};
            }
          `}
        >
          <span
            onClick={() => {
              setCurrentMonth(subMonths(currentMonth, 1));
            }}
          >
            <ChevronLeft />
          </span>
          <div
            css={css`
              margin: 0 1em;
            `}
          >
            <span>
              {currentMonth.getFullYear()}.{`0${currentMonth.getMonth() + 1}`.slice(-2)}
            </span>
          </div>
          <span
            onClick={() => {
              setCurrentMonth(addMonths(currentMonth, 1));
            }}
          >
            <ChevronRight />
          </span>
        </div>
      </div>
    );
  };

  const renderWeek = () => (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f3f3f3;
        border-top: 1px solid #f3f3f3;
        color: #999999;
        padding: 12px 32px;

        span:first-of-type {
          color: ${theme.colors.primary.dark_1};
        }
      `}
    >
      {weekDays.map((w) => (
        <span key={w}>{w.slice(0, 1)}</span>
      ))}
    </div>
  );

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = 'd';
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i += 1) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div key={formattedDate}>
            <div
              css={css`
                display: flex;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                font-weight: 500;
                :hover {
                  background-color: ${hexToRgb(color || '#fb5146', 0.1)};
                }
                ${!isSameMonth(day, monthStart) &&
                css`
                  color: #999999;
                `}
                ${isSameDay(day, parseISO(date)) &&
                css`
                  color: ${color};
                  border-radius: 2px;
                  background-color: ${hexToRgb(color || '#fb5146', 0.13)};
                `}
              `}
              onClick={() => {
                setToggle(false);
                changeDate(toISOStringInKST(cloneDay));
              }}
            >
              {formattedDate}
            </div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div
          key={`${day.getMonth()}-${day.getDate()}`}
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
          `}
        >
          {days}
        </div>
      );
      days = [];
    }
    return <div style={{ padding: '12px 24px' }}>{rows}</div>;
  };

  const message = t('연/월/일을 입력하거나 선택해 주세요.');

  return (
    <>
      <OpenEndedWrapper>
        <CustomTextInput
          Icon={
            <span
              css={css`
                line-height: 0;
                cursor: pointer;
              `}
            >
              <Calendar />
            </span>
          }
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            e.preventDefault();
            if (value.length > 10) {
              return;
            }
            changeDate(autoFormatDate(e.target.value));
          }}
          value={date}
          onIconClick={() => {
            setToggle(!toggle);
          }}
          placeholder={t('YYYY-MM-DD')}
          isValid={DateRegex.test(date)}
          warningMessage={message}
          helperMessage={message}
          borderColor={color}
        />
        {toggle && (
          <div
            css={css`
              position: absolute;
              top: 48px;
              right: 0;
              z-index: 15;
            `}
          >
            <div
              css={css`
                min-width: 312px;
                max-width: 312px;
                border-radius: 5px;
                box-shadow: 0 2px 2px 0 rgba(164, 164, 164, 0.5);
                background-color: #ffffff;
              `}
            >
              <br />
              {renderHeader()}
              {renderWeek()}
              {renderCells()}
            </div>
          </div>
        )}
      </OpenEndedWrapper>
    </>
  );
};

export default observer(DateQuestion);
