export const QUESTION_TYPE = {
  INTRO: 0,
  SINGLE_CHOICE: 1, // 단일 선택,
  MULTIPLE_CHOICE: 2, // 복수 선택
  RANK: 6, // 순위형
  TEXT: 4, // 주관식 문자(서술형)
  TEXT_SHORT: 14, // 주관식 문자(단답형)
  NUMERIC: 3, // 주관식 숫자
  TEXT_DATE: 12, // TEXT_DATE
  TEXT_HOUR: 16, // 시간
  TEXT_AUTO: 8, // TEXT_AUTO
  EVALUATION: 5, // 평가형
  NPS: 20, // NPS
  BARCODE: 13, // 바코드
  SINGLE_IMAGE: 7, // 단일 이미지형
  PRIVACY_POLICY: 21,
  STAR_SCALE: 22,
  PRIVACY_NAME: 27,
  TEXT_PHONE: 11, // 전화번호
  TEXT_EMAIL: 15, // 이메일
  TEXT_ADDRESS: 10, // 주소
  PRIVACY_BIRTH: 28, // 생일
  PRIVACY_OTHER: 29, // 개인정보 기타
  OUTRO: 100,
};

export const OPEN_ENDED_QUESTION_TYPES = [
  QUESTION_TYPE.TEXT_SHORT,
  QUESTION_TYPE.TEXT,
  QUESTION_TYPE.TEXT_DATE,
  QUESTION_TYPE.NUMERIC,
];

export const CHOICE_QUESTION_TYPES = [QUESTION_TYPE.SINGLE_CHOICE, QUESTION_TYPE.MULTIPLE_CHOICE, QUESTION_TYPE.RANK];

export const INTRO_DEFAULT_TITLE = '';

export const OUTRO_DEFAULT_TITLE = '';

//평가형 레벨 4는 척도가 2개
export const TEXT_NEUTRAL_DISPLAY_DISABLE_LEVEL = 4;

export const RANK_OTHERS = -99;

export const RANK_NONE = -1;

export const NumberRegex = /^\d+$/;

export const DateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const PhoneRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/;

export const EmailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

export const BirthRegex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;

export const ReferRegex = /{{Q(\d+)(\[\d+\])?(image)?}}/g;

export enum LOG_LEVEL {
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  TRACE = 'TRACE',
}

export const UID_KEY = 'uid';
export const HMAC_KEY = 'h';
export const EID_KEY = 'eid';
