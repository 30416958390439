import Checkbox from '@/components/common/Checkbox';
import { RANK_OTHERS } from '@/constants/app';
import { css, useTheme } from '@emotion/react';
import { ChoiceModelProps } from 'Models/index';
import React from 'react';
import TextInput from '../common/TextInput';
import { ListItem, ListWrapper, OptionTextWrapper } from '@opensurvey/open-ui';
import styled from '@emotion/styled';
import { OptionProps } from '@/models/ChoiceModel';
import { observer } from 'mobx-react';
import { Image as ImageIcon } from '@/constants/icons';
import theme from '@/styled/theme';
import { sanitize } from 'isomorphic-dompurify';

type Props = {
  optionList: OptionProps[];
  concreteQuestion: ChoiceModelProps;
  hideOptionText: boolean;
  type: number;
};

const ImageOptionListWrapper = ({ optionList, concreteQuestion, hideOptionText, type }: Props) => {
  const { color } = useTheme();
  const { maxCountSelected, questionNo } = concreteQuestion;

  const isPipedEtcOption = concreteQuestion.options.some(
    (option) => option.rank === RANK_OTHERS && option.questionNo !== questionNo
  );

  return (
    <ListWrapper>
      {optionList.map((option, index) => {
        const { text, rank, optionNo, imageUrl } = option;
        const findIndex = concreteQuestion.selectedOptions.findIndex(
          (selectedOption) => selectedOption.optionNo === option.optionNo
        );
        const checked = findIndex >= 0;
        const isNormalOption = rank >= 0;

        return (
          <ListItemWithImage
            tabIndex={index + 1}
            onClick={() => {
              concreteQuestion.changeOption(optionNo);
            }}
            key={optionNo}
            color={color}
            checked={checked}
          >
            {isNormalOption && (
              <ImageWrapper>
                {imageUrl?.edited ? (
                  <Image src={imageUrl.edited} />
                ) : (
                  <Image>
                    <ImageIcon color={theme.colors.grey.grey_3} width={64} height={64} />
                  </Image>
                )}
              </ImageWrapper>
            )}

            <OptionTextWrapper>
              {rank === RANK_OTHERS && !isPipedEtcOption && checked ? (
                <TextInput
                  autoFocus
                  value={concreteQuestion.strAnswer}
                  onChange={(e) => {
                    concreteQuestion.setStrAnswer(e.target.value);
                  }}
                  disabled={!checked && maxCountSelected}
                  css={css`
                    visibility: ${checked ? 'visible' : 'hidden'};
                    border: none !important;
                    font-size: 16px;
                    background-color: transparent;
                    padding: 0;
                    border-radius: 0 !important;
                  `}
                />
              ) : (
                <div
                  css={css`
                    padding-right: 16px;
                    p {
                      margin: 0;
                      padding: 0;
                      width: 100%;
                    }
                  `}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(isNormalOption && hideOptionText ? '' : text),
                  }}
                />
              )}
              <Checkbox type={type} rank={rank} checked={checked} selectedRank={findIndex + 1} />
            </OptionTextWrapper>
          </ListItemWithImage>
        );
      })}
    </ListWrapper>
  );
};

export default observer(ImageOptionListWrapper);

const ListItemWithImage = styled(ListItem)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 350px;
  width: 100%;
  /* https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari webkit bug 대응 */
  transform: translateZ(0);
`;
const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.grey.grey_6};
`;
const Image = styled.div<{ src?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ src }) =>
    src
      ? css`
          background-color: white;
          background-image: url(${src});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        `
      : undefined}
`;
