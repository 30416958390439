import { QUESTION_TYPE } from 'Constants/app';
import { ChoiceModel, EssayModel, ShortAnswerModel, LikertModel, NpsModel } from '../models';
import QuestionSchema, { AnswerSchema, QuestionAnswerSchema, QuestionTypes } from 'Schemas/QuestionSchema';
import NumericModel from '@/models/NumericModel';
import DateModel from '@/models/DateModel';
import PrivacyPolicyModel from '@/models/PrivacyPolicyModel';
import StarScaleModel from '@/models/StarScaleModel';
import {
  BinaryOperation,
  Group,
  instanceOfOperation,
  instanceOfRangeOperation,
  instanceOfTerm,
  Operation,
  RangeOperation,
  Term,
} from '@/schemas/LogicSchema';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import PhoneModel from '@/models/PhoneModel';
import EmailModel from '@/models/EmailModel';
import AddressModel from '@/models/AddressModel';
import NameModel from '@/models/NameModel';
import BirthModel from '@/models/BirthModel';
import PrivacyOtherModel from '@/models/PrivacyOtherModel';

export function createConcreteQuestion(question: QuestionSchema, answer?: QuestionAnswerSchema): QuestionTypes {
  const { type } = question;
  switch (type) {
    case QUESTION_TYPE.SINGLE_CHOICE:
    case QUESTION_TYPE.MULTIPLE_CHOICE:
    case QUESTION_TYPE.RANK:
      return new ChoiceModel(question, answer);
    case QUESTION_TYPE.TEXT:
      return new EssayModel(question, answer);
    case QUESTION_TYPE.TEXT_SHORT:
      return new ShortAnswerModel(question, answer);
    case QUESTION_TYPE.EVALUATION:
      return new LikertModel(question, answer);
    case QUESTION_TYPE.NPS:
      return new NpsModel(question, answer);
    case QUESTION_TYPE.NUMERIC:
      return new NumericModel(question, answer);
    case QUESTION_TYPE.TEXT_DATE:
      return new DateModel(answer);
    case QUESTION_TYPE.PRIVACY_POLICY:
      return new PrivacyPolicyModel(answer);
    case QUESTION_TYPE.STAR_SCALE:
      return new StarScaleModel(question, answer);
    case QUESTION_TYPE.PRIVACY_NAME:
      return new NameModel(answer);
    case QUESTION_TYPE.TEXT_PHONE:
      return new PhoneModel(answer);
    case QUESTION_TYPE.TEXT_EMAIL:
      return new EmailModel(answer);
    case QUESTION_TYPE.TEXT_ADDRESS:
      return new AddressModel(answer);
    case QUESTION_TYPE.PRIVACY_BIRTH:
      return new BirthModel(answer);
    case QUESTION_TYPE.PRIVACY_OTHER:
      return new PrivacyOtherModel(answer);
    default:
      return new ChoiceModel(question, answer);
  }
}

export function hexToRgb(color: string, opacity: number): string {
  const hex = color.trim().replace('#', '');

  const rgb = 3 === hex.length ? hex.match(/[a-f\d]/gi) : hex.match(/[a-f\d]{2}/gi);

  if (rgb) {
    rgb.forEach(function (str, x, arr) {
      if (str.length == 1) str = str + str;

      arr[x] = `${parseInt(str, 16)}`;
    });
    return `rgb(${rgb.join(', ')}, ${opacity})`;
  }
  return '';
}

export function evaluateNode(
  node: StartingNode,
  answers: QuestionAnswerSchema[]
): StartingNode | boolean | number | string | undefined {
  if (node === null || node === undefined) return undefined;
  if (node.nodeType === 'group') {
    return evaluateNode(node.data, answers);
  }
  const leftNode = evaluateNode((node as BinaryOperation).left, answers);
  const rightNode = evaluateNode((node as BinaryOperation).right, answers);
  if (node.nodeType === 'binaryOperation') {
    switch (node.op) {
      case 'and': {
        const result = leftNode && rightNode;
        return node.complement ? !result : result;
      }
      case 'or': {
        const result = leftNode || rightNode;
        return node.complement ? !result : result;
      }
      default:
        return undefined;
    }
  }
  if (instanceOfOperation(node)) {
    if (leftNode === null || rightNode === null) return false;
    return mapOperations(node, leftNode as string | number, rightNode as string | number);
  }
  if (instanceOfTerm(node)) {
    const questionAnswer = findLast(answers, (answer) => answer.questionNo === node.questionNo)?.answer;
    if (!questionAnswer) return false;
    return mapTerms(node, questionAnswer);
  }
  if (instanceOfRangeOperation(node)) {
    const questionAnswer = findLast(answers, (answer) => answer.questionNo === node.value.questionNo)?.answer;
    if (!questionAnswer) return false;
    return mapRangeOperation(node, questionAnswer);
  }
  return node;
}

type StartingNode = Term | Operation | BinaryOperation | RangeOperation | Group;

export function traverseParseTree(
  node: StartingNode,
  answers: QuestionAnswerSchema[]
): string | number | boolean | StartingNode | undefined {
  const evaluationResult = evaluateNode(node, answers);
  return evaluationResult;
}

function mapTerms(term: Term, questionAnswer: AnswerSchema[]): boolean | number | string {
  switch (term.type) {
    case 'answerNo': {
      const result = questionAnswer.some((answer) => answer.num === term.answerNo);
      return term.complement ? !result : result;
    }
    case 'num': {
      if (questionAnswer[0].num === undefined) {
        return false;
      }
      return questionAnswer[0].num;
    }
    case 'str': {
      if (questionAnswer[0].str === undefined) {
        return false;
      }
      return questionAnswer[0].str;
    }
    case 'multipleAnswer': {
      const result = questionAnswer.some(
        (answer) => term.answerList && answer.num !== undefined && term.answerList.includes(answer.num)
      );
      return term.complement ? !result : result;
    }
    default:
      return false;
  }
}

function mapOperations(
  operation: Operation,
  leftOperand: string | number | boolean,
  rightOperand: string | number | boolean
) {
  if (leftOperand === false || rightOperand === false) return false;
  switch (operation.op) {
    case '<': {
      const result = leftOperand < rightOperand;
      return operation.complement ? !result : result;
    }
    case '<=': {
      const result = leftOperand <= rightOperand;
      return operation.complement ? !result : result;
    }
    case '>': {
      const result = leftOperand > rightOperand;
      return operation.complement ? !result : result;
    }
    case '>=': {
      const result = leftOperand >= rightOperand;
      return operation.complement ? !result : result;
    }
    case '==': {
      const result = leftOperand === rightOperand;
      return operation.complement ? !result : result;
    }
    case 'like': {
      const result = (leftOperand as string).includes(rightOperand as string);
      return operation.complement ? !result : result;
    }
    default:
      return;
  }
}

function mapRangeOperation(operation: RangeOperation, questionAnswer: AnswerSchema[]) {
  let result;
  const { lower, upper, complement } = operation;
  if (typeof lower === 'string') {
    const date = new Date(mapTerms(operation.value, questionAnswer) as string);
    const lowerDate = new Date(lower);
    const upperDate = new Date(upper);
    result =
      (isSameDay(date, lowerDate) || isAfter(date, lowerDate)) &&
      (isSameDay(date, upperDate) || isBefore(date, upperDate));
  }
  if (typeof lower === 'number') {
    const value = mapTerms(operation.value, questionAnswer);
    result = value <= upper && value >= lower;
  }
  return complement ? !result : result;
}

export function stripHtml(text: string): string {
  if (text === null || text === '') return '';
  else text = text.toString();
  return text.replace(/(<([^>]+)>)/gi, '');
}

export function prependProtocol(url: string): string {
  if (!/^https?:\/\//i.exec(url)) {
    return `http://${url}`;
  }
  return url;
}

export function autoFormatBirth(value: string): string {
  const replacedString = value.replace(/[^0-9]/g, '').slice(0, 8);

  return replacedString.replace(/^(\d{4})(\d{1,2})(\d{1,2})$/, `$1-$2-$3`);
}

export function autoFormatDate(value: string): string {
  const replacedString = value.replace(/[^0-9]/g, '');
  let temp = '';
  if (replacedString.length > 8) {
    return value;
  }
  if (replacedString.length < 5) {
    temp = replacedString;
  } else if (replacedString.length < 7) {
    temp += `${replacedString.substring(0, 4)}-${replacedString.substring(4)}`;
  } else {
    temp += `${replacedString.substring(0, 4)}-${replacedString.substring(4, 6)}-${replacedString.substring(6)}`;
  }
  return temp;
}

export function toISOStringInKST(date: Date): string {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -14);
}

export function debounce<Params extends unknown[]>(
  func: (...args: Params) => unknown,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function shuffleArray<T>(array: T[]): T[] {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

export function findLast<T>(array: T[], predicate: (value: T) => boolean): T | undefined {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
}

export * from './autoFormatPhoneNumber';
