export function autoFormatPhoneNumber(value: string): string {
  const replacedString = value.replace(/[^0-9]/g, '');

  // 02로 시작하는 번호 핸들
  const k = replacedString.startsWith('02') ? -1 : 0;

  if (replacedString.length > 11 + k) {
    return `${replacedString.slice(0, 3 + k)}-${replacedString.slice(3 + k, 7 + k)}-${replacedString.slice(
      7 + k,
      11 + k
    )}`;
  }

  if (replacedString.length < 4 + k) {
    return replacedString;
  }

  if (replacedString.length < 8 + k) {
    return `${replacedString.slice(0, 3 + k)}-${replacedString.slice(3 + k)}`;
  }

  if (replacedString.length < 11 + k) {
    return `${replacedString.slice(0, 3 + k)}-${replacedString.slice(3 + k, 6 + k)}-${replacedString.slice(6 + k)}`;
  }

  return `${replacedString.slice(0, 3 + k)}-${replacedString.slice(3 + k, 7 + k)}-${replacedString.slice(7 + k)}`;
}
