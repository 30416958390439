import { PhoneRegex } from '@/constants/app';
import { autoFormatPhoneNumber } from '@/lib/Util';
import { observable, action, computed, makeObservable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

class PhoneModel {
  phoneNumber = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.changePhoneNumber(answer[0].str);
      }
    }
    makeObservable(this, {
      phoneNumber: observable,

      changePhoneNumber: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changePhoneNumber = (value: string): void => {
    this.phoneNumber = autoFormatPhoneNumber(value);
  };

  resetAnswer = (): void => {
    this.changePhoneNumber('');
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.phoneNumber) return;
    return [{ str: this.phoneNumber.replace(/[^0-9]/g, '') }];
  }

  get isAnswerValid(): boolean {
    return PhoneRegex.test(this.phoneNumber);
  }
}

export default PhoneModel;
