import { AnswerStoreSchema } from '@/stores/AnswerStore';
import { StoreContext } from '@/contexts/Context';
import { useContext } from 'react';
import { RootStoreSchema } from '@/stores/RootStore';
import { GlobalStoreSchema } from '@/stores/GlobalStore';

export function useAnswerStore(): AnswerStoreSchema {
  const { answerStore } = useContext(StoreContext) as RootStoreSchema;
  return answerStore;
}

export function useGlobalStore(): GlobalStoreSchema {
  const { globalStore } = useContext(StoreContext) as RootStoreSchema;
  return globalStore;
}
