import { hexToRgb } from '@/lib/Util';
import { css, SerializedStyles } from '@emotion/react';
import React, { ChangeEvent, FocusEvent, ForwardedRef, forwardRef, InputHTMLAttributes, useRef, useState } from 'react';

type InputProps = {
  width?: string;
  margin?: string;
  placeholder?: string;
  Icon?: React.ReactNode;
  ActivatedIcon?: React.ReactNode;
  isValid?: boolean;
  activated?: boolean;
  alignIcon?: 'left' | 'right';
  helperMessage?: string;
  warningMessage?: string;
  limitMessage?: string;
  unit?: string;
  onIconClick?: () => void;
  style?: SerializedStyles;
} & InputHTMLAttributes<HTMLInputElement>;

const TextInput = (
  {
    width = '100%',
    margin = '0px',
    placeholder = '',
    isValid = true,
    alignIcon = 'right',
    style,
    Icon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ActivatedIcon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activated,
    helperMessage,
    warningMessage,
    limitMessage,
    unit,
    onIconClick,
    ...props
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const [isFocused, setIsFocused] = useState(true);
  const iconRef = useRef<HTMLDivElement>(null);

  props.onFocus = ((f) =>
    function onFocus(e: FocusEvent<HTMLInputElement>): void {
      if (f) {
        f.apply(props.onFocus, [e]);
      }
      setIsFocused(true);
      return;
    })(props.onFocus);

  props.onBlur = ((f) =>
    function onBlur(e: FocusEvent<HTMLInputElement>): void {
      if (f) {
        f.apply(props.onBlur, [e]);
      }
      setIsFocused(false);
      return;
    })(props.onBlur);

  return (
    <div
      css={css`
        width: ${width};
        margin: ${margin};
        position: relative;
      `}
    >
      <div
        css={css`
          position: relative;

          input {
            -webkit-appearance: none; //remove inner shadow
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
              //remove number up down button
              -webkit-appearance: none;
              margin: 0;
            }
          }
        `}
      >
        <input
          ref={ref}
          value={props.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange && props.onChange(e)}
          onClick={(e) => props.onClick && props.onClick(e)}
          placeholder={placeholder}
          css={css`
            padding: 12px;
            ${alignIcon === 'left' &&
            css`
              padding-left: ${Icon || unit
                ? `calc(${iconRef.current ? iconRef.current.offsetWidth : 0}px + 12px + 16px)`
                : '12px'};
            `}
            ${alignIcon === 'right' &&
            css`
              padding-right: ${Icon || unit
                ? `calc(${iconRef.current ? iconRef.current.offsetWidth : 0}px + 12px + 16px)`
                : '12px'};
            `}
            border-radius: 8px;
            outline: none;
            border: 1px solid ${hexToRgb('#fb5146', 0.4)};
            width: 100%;
            display: block;
            box-sizing: border-box;

            @media screen and (min-width: 415px) {
              font-size: 16px;
            }
            @media screen and (max-width: 414px) {
              font-size: 14px;
            }

            :focus {
              border: 1px solid #fb5146;
            }

            ::placeholder {
              color: rgba(29, 37, 45, 0.38);
            }
            ${style}
          `}
          {...props}
        />
        <div
          css={css`
            position: absolute;
            height: 100%;
            ${alignIcon === 'left' ? `left: 12px;` : `right: 12px;`}
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            user-select: none;
          `}
          onClick={onIconClick}
          ref={iconRef}
        >
          <span
            css={css`
              color: rgba(29, 37, 45, 0.38);
              font-size: 14px;
              margin-right: 4px;
            `}
          >
            {unit}
          </span>
          {Icon}
        </div>
      </div>
      {(helperMessage || warningMessage || limitMessage) && (
        <div
          css={css`
            white-space: nowrap;
            pointer-events: none;
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            width: 100%;

            p {
              font-size: 12px;
              font-weight: 400;
              letter-spacing: -0.3px;
              margin: 0;
            }
          `}
        >
          <p>
            {isFocused ? (
              <span
                css={css`
                  color: rgba(29, 37, 45, 0.6);
                `}
              >
                {helperMessage}
              </span>
            ) : (
              !isValid && (
                <span
                  css={css`
                    color: #9b0001;
                  `}
                >
                  {warningMessage}
                </span>
              )
            )}
            &nbsp;
          </p>
          {limitMessage && (
            <p
              css={css`
                color: rgba(29, 37, 45, 0.87);
                margin-left: auto !important;
              `}
            >
              {limitMessage}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default forwardRef(TextInput);
