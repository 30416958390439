import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

class NameModel {
  name = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.name = answer[0].str;
      }
    }
    makeObservable(this, {
      name: observable,

      changeName: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeName = (value: string): void => {
    this.name = value;
  };

  resetAnswer = (): void => {
    this.changeName('');
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.name) return;
    return [{ str: this.name }];
  }

  get isAnswerValid(): boolean {
    return this.name.trim().length > 0;
  }
}

export default NameModel;
