import React from 'react';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { useAnswerStore } from '@/hooks/useStore';
import { CircleClose } from '@/constants/icons';
import { CustomTextInput, OpenEndedWrapper } from '@opensurvey/open-ui';
import PhoneModel from '@/models/PhoneModel';
import { useTranslation } from 'react-i18next';

const Phone = (): JSX.Element => {
  const { color } = useTheme();
  const { currentQuestion } = useAnswerStore();
  const { concreteQuestion } = currentQuestion as { concreteQuestion: PhoneModel };
  const { phoneNumber, isAnswerValid, changePhoneNumber, resetAnswer } = concreteQuestion;
  const { t } = useTranslation();

  const message = t('전화번호를 입력해 주세요.');

  return (
    <OpenEndedWrapper>
      <CustomTextInput
        pattern="\d*"
        borderColor={color}
        placeholder={message}
        value={phoneNumber}
        Icon={phoneNumber.length > 0 ? <CircleClose /> : null}
        onIconClick={resetAnswer}
        helperMessage={message}
        warningMessage={message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          changePhoneNumber(value);
        }}
        isValid={isAnswerValid}
      />
    </OpenEndedWrapper>
  );
};

export default observer(Phone);
