const profile = process.env.REACT_APP_PROFILE;

export const FEEDBACK_API_URL =
  profile === 'qa'
    ? 'https://aws-qa01-front-api.feedback.io'
    : profile === 'qa02'
    ? 'https://aws-qa02-front-api.feedback.io'
    : profile === 'dev'
    ? 'https://aws-dev01-front-api.feedback.io'
    : 'https://front-api.feedback.io';

export const FEEDBACK_TEAM_URL =
  profile === 'qa'
    ? `https://qa-team.feedback.io`
    : profile === 'qa02'
    ? `https://qa02-team.feedback.io`
    : profile === 'dev'
    ? `https://dev-team.feedback.io`
    : `https://team.feedback.io`;

export const FEEDBACK_HOMEPAGE_URL =
  profile === 'qa'
    ? `https://aws-qa.feedback.io`
    : profile === 'qa02'
    ? `https://aws-qa02.feedback.io`
    : profile === 'dev'
    ? `https://aws-dev.feedback.io`
    : `https://feedback.io`;

export const FEEDBACK_I18N_URL =
  profile === 'qa'
    ? `https://qa-front.feedback.io/i18n`
    : profile === 'qa02'
    ? `https://qa02-front.feedback.io/i18n`
    : profile === 'dev'
    ? `https://dev-front.feedback.io/i18n`
    : `https://front.feedback.io/i18n`;
