import { QUESTION_TYPE, RANK_NONE } from '@/constants/app';
import { hexToRgb } from '@/lib/Util';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CircleCheckbox, CircleCheckboxChecked, MultipleCircleChecked } from 'Constants/icons';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

interface CheckboxProps {
  checked: boolean | undefined;
  type: number;
  selectedRank: number;
  rank: number;
  onChange?: () => void;
}

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  transition: all 150ms;
  margin-right: 15px;

  @media screen and (max-width: 414px) {
    margin-right: 11px;
  }
`;

const Checkbox = ({ checked, type, selectedRank, rank, ...props }: CheckboxProps): JSX.Element => {
  const { color } = useTheme();

  const icon = useMemo(() => {
    switch (type) {
      case QUESTION_TYPE.SINGLE_CHOICE:
        return {
          checked: <CircleCheckboxChecked color={color} />,
          unchecked: <CircleCheckbox color={color} />,
        };
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        return {
          checked: <MultipleCircleChecked color={color} />,
          unchecked: <CircleCheckbox color={color} />,
        };
      case QUESTION_TYPE.RANK:
        return {
          checked:
            rank === RANK_NONE ? (
              <MultipleCircleChecked color={color} />
            ) : (
              <div
                css={css`
                  display: flex;
                  text-align: center;
                  box-sizing: border-box;
                  width: 24px;
                  height: 24px;
                  background-color: ${hexToRgb(color || '#fb5146', 0.15)};
                  border: 1px solid ${color || '#fb5146'};
                  border-radius: 50%;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                    font-size: 12px;
                    margin: auto;
                    color: ${color || '#fb5146'};
                  `}
                >
                  {selectedRank}
                </span>
              </div>
            ),
          unchecked: <CircleCheckbox color={color} />,
        };
      default:
        return null;
    }
  }, [type, rank, selectedRank]);

  return (
    <div
      className="custom-choice-check"
      css={css`
        display: inline-block;
      `}
    >
      <HiddenCheckbox type="checkbox" {...props} />
      <StyledCheckbox>{checked ? icon?.checked : icon?.unchecked}</StyledCheckbox>
    </div>
  );
};

export default observer(Checkbox);
