import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  S2,
  CustomButton,
  B2,
  ButtonGroup,
} from '@opensurvey/open-ui';
import { FEEDBACK_HOMEPAGE_URL } from '@/constants/urls';

type OptOutProps = {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  hasOptedOut: boolean;
  isLoading: boolean;
};

const DesktopOptOut = ({ onCancel, onConfirm, hasOptedOut, isLoading }: OptOutProps): JSX.Element => {
  return (
    <Dialog open>
      <DialogTitle />
      <DialogContent>
        <S2 fontWeight={700}>수신 거부</S2>
        <B2 margin="16px 0 0 0">{hasOptedOut ? '정상적으로 수신거부 처리되었습니다.' : '수신 거부하시겠습니까?'}</B2>
      </DialogContent>
      <DialogActions>
        <ButtonGroup rightAlign>
          {!hasOptedOut && (
            <CustomButton color="secondary" variant="outlined" onClick={onCancel}>
              취소
            </CustomButton>
          )}
          <CustomButton
            color="secondary"
            variant="contained"
            isLoading={isLoading}
            onClick={
              hasOptedOut
                ? () => {
                    window.location.href = FEEDBACK_HOMEPAGE_URL;
                  }
                : onConfirm
            }
          >
            확인
          </CustomButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default DesktopOptOut;
