import { QuestionWrapper } from '@opensurvey/open-ui';
import InOutro from '@/components/InOutro';
import React, { useEffect, useRef } from 'react';

const Complete = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, []);

  return (
    <QuestionWrapper ref={ref}>
      <InOutro type={'outro'} />
    </QuestionWrapper>
  );
};

export default Complete;
