import { DateRegex } from '@/constants/app';
import { parseISO, format } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

export interface DateModelProps {
  changeDate: (date: string) => void;
  resetAnswer: () => void;
  date: string;
  isAnswerValid: boolean;
  answer?: AnswerSchema[];
}

class DateModel implements DateModelProps {
  date = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0) {
        this.date = answer[0].str ? format(parseISO(answer[0].str), 'yyyy-MM-dd') : this.date;
      }
    }
    makeObservable(this, {
      date: observable,

      resetAnswer: action,
      changeDate: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  resetAnswer = (): void => {
    this.date = '';
  };

  changeDate = (date: string): void => {
    this.date = date;
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.date) return;
    return [{ str: this.date }];
  }

  get isAnswerValid(): boolean {
    return DateRegex.test(this.date);
  }
}

export default DateModel;
