import { useEffect, useState } from 'react';
import { useGlobalStore } from '@/hooks/useStore';
import { DEFAULT_LANG } from '@/i18n/config';

const LANG_LIST_WITH_COUNTRY = {
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
} as const;

const LANG_LIST = {
  de: 'de',
  ru: 'ru',
  ms: 'ms',
  vi: 'vi',
  es: 'es',
  en: 'en',
  it: 'it',
  id: 'id',
  ja: 'ja',
  th: 'th',
  tr: 'tr',
  fr: 'fr',
  ko: 'ko',
  hi: 'hi',
} as const;

export function useInitLanguageFromSearchParam() {
  const { initLanguage, cookies } = useGlobalStore();
  const [isLoading, setIsLoading] = useState(true);

  const getBrowserLanguage = (): string | undefined => {
    const browserLang = navigator.language;
    const langWithCountry = LANG_LIST_WITH_COUNTRY[browserLang as keyof typeof LANG_LIST_WITH_COUNTRY];
    const lang = LANG_LIST[browserLang.slice(0, 2).toLowerCase() as keyof typeof LANG_LIST];

    return langWithCountry || lang;
  };

  useEffect(() => {
    const effect = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const lang = searchParams.get('lang') || cookies.get('lang') || getBrowserLanguage() || DEFAULT_LANG;
        await initLanguage(lang);
      } finally {
        setIsLoading(false);
      }
    };

    void effect();
  }, []);

  return { isLoading };
}
