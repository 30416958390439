/* eslint-disable no-case-declarations */
import QuestionSchema, { QuestionTypes, QuestionAnswerSchema, PipingType } from 'Schemas/QuestionSchema';
import { autoFormatPhoneNumber, createConcreteQuestion, stripHtml } from 'Lib/Util';
import { QUESTION_TYPE, RANK_NONE, RANK_OTHERS, ReferRegex } from '@/constants/app';
import { LikertModelProps } from './LikertModel';
import { ChoiceModelProps, OptionProps } from './ChoiceModel';
import { Nullable } from '@/schemas/SharedSchema';

export interface QuestionProps {
  questionNo: number;
  type: number;
  text: string;
  rank: number;
  description: string;
  logic: string;
  skipToQuestionNo?: number;
  concreteQuestion: QuestionTypes;
  replaceReferString: (currentQuestionIndex: number, questions: QuestionProps[]) => string;
  isHidden?: boolean;
  pipingType: Nullable<PipingType>;
  pipingQuestionNo: Nullable<number>;
}

class Question implements QuestionProps {
  questionNo;

  type = 1;

  text = '';

  rank = 0;

  description = '';

  logic = '';

  skipToQuestionNo;

  concreteQuestion;

  isHidden?: boolean;

  pipingType: Nullable<PipingType>;

  pipingQuestionNo: Nullable<number>;

  constructor(question: QuestionSchema, answer?: QuestionAnswerSchema) {
    const {
      questionNo,
      rank,
      type,
      text,
      logic,
      skipToQuestionNo,
      displayOptions,
      isHidden,
      pipingQuestionNo,
      pipingType,
    } = question;
    this.questionNo = questionNo;
    this.type = type;
    this.text = text;
    this.rank = rank;
    this.description = displayOptions?.feedbackDisplayOptions?.description || '';
    this.logic = logic;
    this.skipToQuestionNo = skipToQuestionNo;
    this.concreteQuestion = createConcreteQuestion(question, answer);
    this.isHidden = isHidden;
    this.pipingType = pipingType;
    this.pipingQuestionNo = pipingQuestionNo;
  }

  replaceReferString = (currentQuestionIndex: number, questions: QuestionProps[]): string => {
    const questionTitle = this.text;

    return questionTitle.replace(
      ReferRegex,
      (referString, questionIndexString: string, rankString: string, imageString: string) => {
        const refQuestionIndex = +questionIndexString - 1;

        if (refQuestionIndex >= currentQuestionIndex) {
          return referString;
        }

        const refQuestion = questions[refQuestionIndex];
        const answerList = refQuestion.concreteQuestion?.answer as { num: number; str: string }[];

        if (!answerList?.[0]) {
          return referString;
        }

        const answer = answerList[0];
        const { num, str } = answer;

        switch (refQuestion.type) {
          case QUESTION_TYPE.EVALUATION:
            const {
              evaluationDisplayOptions: { horizontal, evalTexts },
            } = refQuestion.concreteQuestion as LikertModelProps;

            const label = stripHtml(evalTexts?.[num - 1] || referString);
            return horizontal ? `${num}` : label;

          case QUESTION_TYPE.STAR_SCALE:
          case QUESTION_TYPE.NPS:
          case QUESTION_TYPE.NUMERIC:
            return `${num}`;

          case QUESTION_TYPE.TEXT_SHORT:
          case QUESTION_TYPE.TEXT:
          case QUESTION_TYPE.TEXT_DATE:
          case QUESTION_TYPE.TEXT_EMAIL:
          case QUESTION_TYPE.PRIVACY_NAME:
          case QUESTION_TYPE.PRIVACY_BIRTH:
          case QUESTION_TYPE.PRIVACY_OTHER:
            return str;

          case QUESTION_TYPE.TEXT_PHONE:
            return autoFormatPhoneNumber(str);

          case QUESTION_TYPE.TEXT_ADDRESS:
            return str.split(' | ').join(' ');

          case QUESTION_TYPE.SINGLE_CHOICE:
          case QUESTION_TYPE.RANK:
          case QUESTION_TYPE.MULTIPLE_CHOICE:
            const { options } = refQuestion.concreteQuestion as ChoiceModelProps;

            const optionMap = options.reduce((map, option) => {
              return map.set(option.rank, option);
            }, new Map<number, OptionProps>());

            if (rankString) {
              const targetRank = +rankString.replace(/\[|\]/g, '') - 1;

              if (isNaN(targetRank)) {
                return referString;
              }

              const answer = answerList[targetRank];
              const optionRank = answer?.num;

              if (!optionRank) {
                return referString;
              }

              const option = optionMap.get(optionRank);
              const isEtc = option?.rank === RANK_OTHERS;
              const isNone = option?.rank === RANK_NONE;

              if (isEtc) {
                return answer?.str || referString;
              }

              if (!isNone && imageString) {
                return `<img src="${option?.imageUrl?.edited || ''}" style="width: 350px;" alt="이미지">`;
              }

              return stripHtml(option?.text ?? referString);
            }

            const text = answerList
              .map((answer) => {
                const { num, str } = answer;

                const option = optionMap.get(num);
                const isEtc = option?.rank === RANK_OTHERS;
                const isNone = option?.rank === RANK_NONE;

                if (isEtc) {
                  return str;
                }

                if (!isNone && imageString) {
                  return `<img src="${option?.imageUrl?.edited || ''}" style="width: 350px;" alt="이미지">`;
                }

                return stripHtml(option?.text || referString);
              })
              .join(imageString ? '<br/>' : ', ');

            return text;

          default:
            return referString;
        }
      }
    );
  };
}

export default Question;
