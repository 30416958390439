import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { sanitize } from 'isomorphic-dompurify';

type EvalTextProps = {
  text: string;
  customCss?: SerializedStyles;
  align?: 'start' | 'center' | 'end';
};

const EvalText = ({ text, customCss, align = 'start' }: EvalTextProps): JSX.Element => {
  return (
    <p
      css={[
        css`
          color: rgba(29, 37, 45, 0.6);
          @media screen and (min-width: 415px) {
            font-size: 16px;
          }
          @media screen and (max-width: 414px) {
            font-size: 14px;
          }

          * {
            width: 100% !important;
            ${align === 'end' &&
            css`
              text-align: right;
            `};
            ${align === 'center' &&
            css`
              text-align: center;
            `}
          }
        `,
        customCss,
      ]}
      dangerouslySetInnerHTML={{ __html: sanitize(text) }}
    />
  );
};

export default EvalText;
