import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import UnderConstruction from './components/UnderConstruction';
import { LOG_LEVEL } from './constants/app';
import { useGlobalStore } from './hooks/useStore';
import { logError } from './lib/ApiUtil';
import Answer from './pages/Answer';
import Completed from './pages/Completed';
import Invalid from './pages/Invalid';
import OptOut from './pages/OptOut';
import Preview from './pages/Preview';
import Stopped from './pages/Stopped';

function App({ cookies }: { cookies: Cookies }): JSX.Element {
  const history = useHistory();
  const globalStore = useGlobalStore();
  const { setHistory, setCookies, setScrollRef } = globalStore;
  const scrollRef = useRef<HTMLDivElement>(null);
  setCookies(cookies);
  setHistory(history);

  const preventRightClickOnImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    const tagName = e.target.tagName.toLowerCase();
    if (tagName === 'img') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    // 모바일에서 100vh가 기대한 것과 다른 것을 보정합니다
    // ref https://dmstjq92.medium.com/모바일-ios-safari-chrome-에서-100vh-스크롤-생기는-문제-종결-682c5e9d068d
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const resizeHandler = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const errorHandler = (e: ErrorEvent) => {
      const { message, filename, lineno, colno, error } = e;

      if (!filename.includes('/static/js')) {
        return;
      }

      const errorMessage = `[Frontend error] ${message}
        url: ${window.location.href}
        file: ${filename}
        line: ${lineno}
        column: ${colno}
        stack: ${(error as Error)?.stack ?? '-'}
        `;
      void logError(errorMessage, LOG_LEVEL.ERROR);
    };

    window.addEventListener('resize', resizeHandler);
    window.addEventListener('error', errorHandler);

    setScrollRef(scrollRef);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  const isUnderConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === 'maintenance';

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        justify-content: center;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        min-height: fill-available;
        min-height: -webkit-fill-available;
        overflow: hidden;
      `}
    >
      <div
        ref={scrollRef}
        css={css`
          width: 100%;
          max-width: 720px;
          min-height: 100vh;
          height: 100vh;
          height: calc(var(--vh, 1vh) * 100);
          min-height: calc(var(--vh, 1vh) * 100);
          overflow-y: auto;
        `}
        onContextMenu={preventRightClickOnImage}
      >
        <Switch>
          <Route exact path="/r/:serial" component={Answer} />
          <Route exact path="/invalid" component={Invalid} />
          <Route exact path="/preview" component={Preview} />
          <Route exact path="/test" component={Preview} />
          <Route exact path="/stopped" component={Stopped} />
          <Route exact path="/completed" component={Completed} />
          <Route exact path="/opt-out" component={OptOut} />
          <Route exact path="/oo" component={OptOut} />
          <Redirect to="/invalid" />
        </Switch>
      </div>
    </div>
  );
}

export default withCookies(observer(App));
