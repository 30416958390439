import i18n, { DEFAULT_LANG } from '@/i18n/config';
import Form, { FormModel } from '@/models/Form';
import FormSchema from '@/schemas/FormSchema';
import { addDays, parseISO } from 'date-fns';
import { getFormRequest, getI18NData, postEnter } from 'Lib/ApiUtil';
import { action, makeObservable, observable } from 'mobx';
import Question, { QuestionProps } from 'Models/Question';
import { RefObject } from 'react';
import { Cookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router-dom';
import { QuestionAnswerSchema } from 'Schemas/QuestionSchema';
import { RootStoreSchema } from 'Stores/RootStore';

export type GlobalStoreSchema = GlobalStore;

class GlobalStore implements GlobalStoreSchema {
  rootStore;

  answerToken = '';

  formNo = 0;

  cookies = {} as Cookies;

  questions = [] as QuestionProps[];

  history = {} as RouteComponentProps['history'];

  answers = [] as QuestionAnswerSchema[];

  form = {} as FormModel;

  scrollRef?: RefObject<HTMLDivElement>;

  constructor(rootStore: RootStoreSchema) {
    this.rootStore = rootStore;
    makeObservable(this, {
      questions: observable,
      answers: observable,
      cookies: observable.ref,
      formNo: observable,
      answerToken: observable,
      setHistory: action,
      addAnswers: action,
      enterAnswer: action,
      setCookies: action,
      goBack: action,
      resetAnswers: action,
      goToQuestion: action,
    });
  }

  setCookies = (cookies: Cookies): void => {
    this.cookies = cookies;
  };

  setHistory = (history: RouteComponentProps['history']): void => {
    this.history = history;
  };

  setScrollRef = (ref: RefObject<HTMLDivElement>): void => {
    this.scrollRef = ref;
  };

  scrollToTop = () => {
    if (this.scrollRef?.current) {
      this.scrollRef.current.scrollTop = 0;
    }
  };

  goBack = (): void => {
    const {
      questions,
      answers,
      rootStore: {
        answerStore: { currentQuestionIndex, setCurrentQuestionIndex, setIsFinished, isFinished, initialQuestionIndex },
      },
    } = this;
    if (questions[currentQuestionIndex].concreteQuestion?.answer) {
      questions[currentQuestionIndex].concreteQuestion?.resetAnswer();
    }
    if (answers.length > 0) {
      const index = questions.findIndex((question) => question.questionNo === answers[answers.length - 1].questionNo);
      setCurrentQuestionIndex(index);
      const clone = answers.slice();
      clone.splice(clone.length - 1, 1);
      this.answers = clone;
    } else {
      setCurrentQuestionIndex(initialQuestionIndex);
    }
    if (isFinished) {
      setIsFinished(false);
    }

    this.scrollToTop();
  };

  resetAnswers = (): void => {
    const {
      questions,
      rootStore: {
        answerStore: { setCurrentQuestionIndex, setIsFinished, initialQuestionIndex },
      },
    } = this;
    this.answers = [];
    setCurrentQuestionIndex(initialQuestionIndex);
    setIsFinished(false);
    questions.forEach((question) => question.concreteQuestion?.resetAnswer());
  };

  goToQuestion = (questionNo: number): void => {
    const {
      questions,
      rootStore: {
        answerStore: { setCurrentQuestionIndex, isFinished, setIsFinished },
      },
    } = this;
    if (isFinished) {
      setIsFinished(false);
    }
    const index = questions.findIndex((question) => question.questionNo === questionNo);
    setCurrentQuestionIndex(index);
    return;
  };

  getForm = async (code: string): Promise<void> => {
    const response = await getFormRequest(code);
    const form = response.data;
    const { questions } = form;

    this.form = new Form(form);
    void this.initLanguage(this.form.lang);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.questions = (questions || []).map((question: any) => {
      const { questionNo } = question;
      // answer 찾기
      const found = this.answers.find((answer) => {
        return answer.questionNo === questionNo;
      });
      return new Question(question, found);
    });

    this.questions.sort((a, b) => {
      return a.rank - b.rank;
    });

    const { answerStore } = this.rootStore;
    answerStore.simulateAnswers();
  };

  setForm = (form: FormSchema): void => {
    this.form = new Form(form);
    void this.initLanguage(this.form.lang);
    this.questions = form.questions.map((question) => new Question(question));
  };

  addAnswers = (answer: { questionNo: number; answer: Array<{ num: number; str: string }> }): void => {
    const clone = this.answers.slice();
    clone.push(answer);
    this.answers = clone;
  };

  enterAnswer = async ({
    serial,
    uid,
    hmac,
  }: {
    serial: string;
    uid: string | null;
    hmac: string | null;
  }): Promise<boolean> => {
    const answerKey = `${serial.toLowerCase()}${uid ? `-${uid}` : ''}`;
    const token = this.cookies.get(answerKey);
    try {
      const response = await postEnter({ serial, answerToken: token, uid, hmac });

      const { answerToken, formNo, answers, expireAt, channelStatus, lang } = response.data;
      this.formNo = formNo;
      const langParam = lang ? `?lang=${lang}` : '';
      if (response.data.isSubmitted) {
        this.history.push(`/completed${langParam}`);
        return false;
      } else {
        switch (channelStatus) {
          case 'STOP': {
            this.history.push(`/stopped${langParam}`);
            return false;
          }
          case 'RUNNING': {
            this.answerToken = answerToken;
            this.cookies.set(answerKey, answerToken, {
              path: '/',
              expires: parseISO(expireAt),
            });
            this.answers = answers;
            return true;
          }
          case 'UNAVAILABLE': {
            this.history.push(`/invalid${langParam}`);
            return false;
          }
          default: {
            this.history.push(`/invalid${langParam}`);
            return false;
          }
        }
      }
    } catch (e) {
      this.history.push(`/invalid`);
      return false;
    }
  };

  initLanguage = async (lang: string) => {
    const i18nResource = await getI18NData(lang);
    i18n.addResourceBundle(lang, 'translation', i18nResource, false, true);

    // html:lang 업데이트
    document.querySelector('html')?.setAttribute('lang', lang);

    // title 업데이트: 한국어인 경우 한글로 업데이트
    if (lang === DEFAULT_LANG) {
      document.title = '피드백을 기다리고 있어요';
    }

    // 쿠키에 저장 의도: form 정보를 요청하지 않는 static 페이지에서 마지막으로 설문했덤 폼의 언어로 보여주기 위함
    this.cookies.set('lang', lang, {
      path: '/',
      expires: addDays(new Date(), 7),
    });

    return i18n.changeLanguage(lang);
  };
}

export default GlobalStore;
