import { FEEDBACK_HOMEPAGE_URL } from '@/constants/urls';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { B1, B2 } from '@opensurvey/open-ui';
import React from 'react';

type MobileOptOutProps = {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  hasOptedOut: boolean;
  isLoading: boolean;
};

const MobileOptOut = ({ onCancel, onConfirm, hasOptedOut, isLoading }: MobileOptOutProps): JSX.Element => {
  return (
    <Backdrop>
      <AlertWindow>
        <B1 margin="32px 0 6px 0" fontWeight={700}>
          수신거부
        </B1>
        <B2
          margin="0 0 32px 0"
          css={css`
            line-height: 22px;
          `}
        >
          {hasOptedOut ? '정상적으로 수신거부 처리되었습니다.' : '수신 거부하시겠습니까?'}
        </B2>
        <Flex>
          {!hasOptedOut && (
            <ConfirmButton onClick={() => onCancel()}>
              <B2 color={'#626ffc'} fontWeight={700}>
                취소
              </B2>
            </ConfirmButton>
          )}
          <ConfirmButton
            onClick={
              hasOptedOut
                ? () => {
                    window.location.href = FEEDBACK_HOMEPAGE_URL;
                  }
                : () => {
                    if (!isLoading) {
                      void onConfirm();
                    }
                  }
            }
          >
            <B2 color={'#626ffc'} fontWeight={700}>
              확인
            </B2>
          </ConfirmButton>
        </Flex>
      </AlertWindow>
    </Backdrop>
  );
};

export default MobileOptOut;

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const AlertWindow = styled.div`
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 312px;
  height: 166px;
  overflow: hidden;
  position: relative;
  text-align: center;
`;

const ConfirmButton = styled.button`
  outline: none;
  border: none;
  height: 48px;
  width: 100%;
  background: white;
  box-shadow: inset 0px 1px 0px 1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
`;
