import { BirthRegex } from '@/constants/app';
import { autoFormatBirth } from '@/lib/Util';
import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

class BirthModel {
  birth = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.birth = autoFormatBirth(answer[0].str);
      }
    }
    makeObservable(this, {
      birth: observable,

      changeBirth: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeBirth = (value: string): void => {
    this.birth = autoFormatBirth(value);
  };

  resetAnswer = (): void => {
    this.changeBirth('');
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.birth) return;
    return [{ str: this.birth }];
  }

  get isAnswerValid(): boolean {
    return BirthRegex.test(this.birth);
  }
}

export default BirthModel;
