import { PaddingWrapper } from '@opensurvey/open-ui';
import Answer from '@/components/Answer';
import { FEEDBACK_TEAM_URL } from '@/constants/urls';
import { useAnswerStore, useGlobalStore } from '@/hooks/useStore';
import Complete from '@/pages/Complete';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import Navigate from '../question/Navigate';
import { Cookies } from 'react-cookie';
import { MessageData } from '@/schemas/SharedSchema';
import styled from '@emotion/styled';

const Preview = (): JSX.Element => {
  const { resetAnswers, goToQuestion } = useGlobalStore();
  const { isFinished, currentQuestionIndex } = useAnswerStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const messageListener = (event: MessageEvent<MessageData>) => {
      if (event.origin !== FEEDBACK_TEAM_URL) {
        return;
      }
      switch (event.data.type) {
        case 'reset': {
          resetAnswers();
          break;
        }
        case 'moveTo': {
          const cookies = new Cookies();
          cookies.set('skipLogic', 'true');
          goToQuestion(event.data.questionNo);
          break;
        }
        default: {
          return;
        }
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [currentQuestionIndex, isFinished]);

  return (
    <PaddingWrapperFixed ref={ref}>
      {isFinished ? <Complete /> : <Answer isPreview />}
      <Navigate isPreview />
    </PaddingWrapperFixed>
  );
};

export default observer(Preview);

export const PaddingWrapperFixed = styled(PaddingWrapper)`
  height: auto;
  overflow: visible;

  @media screen and (max-width: 414px) {
    position: fixed;
    height: 100%;
  }
`;
