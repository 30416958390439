import Checkbox from '@/components/common/Checkbox';
import { QUESTION_TYPE, RANK_OTHERS } from '@/constants/app';
import { Refresh } from '@/constants/icons';
import { css, useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { ChoiceModelProps } from 'Models/index';
import { QuestionProps } from 'Models/Question';
import React, { useEffect, useMemo } from 'react';
import TextInput from '../common/TextInput';
import { ChoiceWrapper, HintMessageWrapper, ListWrapper, ListItem, OptionTextWrapper } from '@opensurvey/open-ui';
import ImageOptionListWrapper from './ImageOptionListWrapper';
import { useGlobalStore } from '@/hooks/useStore';
import { getRealOptionList } from '@/models/ChoiceModel';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'isomorphic-dompurify';

interface ChoiceProps {
  question: QuestionProps;
}

const Choice = ({ question }: ChoiceProps): JSX.Element => {
  const { color } = useTheme();
  const { questions } = useGlobalStore();
  const { concreteQuestion, type } = question as { type: number; concreteQuestion: ChoiceModelProps };
  const { t } = useTranslation();

  // 보기가 자신의 보기가 아니면 파이핑된 보기임
  const isPipedEtcOption = concreteQuestion.options.some(
    (option) => option.rank === RANK_OTHERS && option.questionNo !== question.questionNo
  );

  const {
    realMaxSelection: maxSelection,
    realMinSelection: minSelection,
    maxCountSelected,
    displayOptions,
    isPipingQuestion,
    setRealOptions,
  } = concreteQuestion;

  const optionList = useMemo(() => getRealOptionList({ questions, question }), [question, questions]);

  const originQuestionNo = concreteQuestion.options.find(
    (option) => option.questionNo !== question.questionNo
  )?.questionNo;
  const originQuestion = questions.find((question) => question.questionNo === originQuestionNo);

  const choiceAnswerDisplayOptions = isPipingQuestion
    ? (originQuestion?.concreteQuestion as ChoiceModelProps)?.displayOptions?.choiceAnswerDisplayOptions
    : displayOptions?.choiceAnswerDisplayOptions;

  const imageInOptions = !!choiceAnswerDisplayOptions?.imageInOptions;
  const hideOptionText = !!choiceAnswerDisplayOptions?.hideOptionText;

  useEffect(() => {
    setRealOptions(optionList);
  }, [optionList]);

  const sameMinMax = minSelection === maxSelection;

  return (
    <ChoiceWrapper>
      {(type === QUESTION_TYPE.SINGLE_CHOICE || !!(minSelection && maxSelection)) && (
        <HintMessageWrapper
          css={css`
            ${imageInOptions && 'max-width: 350px;'}
          `}
        >
          <div>
            {type === QUESTION_TYPE.SINGLE_CHOICE && t('1개 보기 선택', { min: 1 })}
            {type === QUESTION_TYPE.MULTIPLE_CHOICE &&
              (sameMinMax
                ? t('1개 보기 선택', { min: minSelection || 0 })
                : t('1~3개 보기 선택', { min: minSelection || 0, max: maxSelection || 0 }))}
            {type === QUESTION_TYPE.RANK &&
              (sameMinMax
                ? t('1개 순위 선택', { min: minSelection || 0 })
                : t('1~3개 순위 선택', { min: minSelection || 0, max: maxSelection || 0 }))}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
            `}
            onClick={() => {
              concreteQuestion.resetAnswer();
            }}
          >
            <Refresh />
            <span
              css={css`
                margin-left: 4px;
                font-size: 12px;
              `}
            >
              {t('선택 초기화')}
            </span>
          </div>
        </HintMessageWrapper>
      )}

      {imageInOptions ? (
        <ImageOptionListWrapper
          type={type}
          hideOptionText={hideOptionText}
          concreteQuestion={concreteQuestion}
          optionList={optionList}
        />
      ) : (
        <ListWrapper>
          {optionList.map((option, index) => {
            const { text, rank, optionNo } = option;
            const findIndex = concreteQuestion.selectedOptions.findIndex(
              (selectedOption) => selectedOption.optionNo === option.optionNo
            );
            const checked = findIndex >= 0;

            return (
              <ListItem
                tabIndex={index + 1}
                onClick={() => {
                  concreteQuestion.changeOption(optionNo);
                }}
                key={optionNo}
                color={color}
                checked={checked}
              >
                <OptionTextWrapper>
                  {rank === RANK_OTHERS && !isPipedEtcOption && checked ? (
                    <TextInput
                      autoFocus
                      value={concreteQuestion.strAnswer}
                      onChange={(e) => {
                        concreteQuestion.setStrAnswer(e.target.value);
                      }}
                      disabled={!checked && maxCountSelected}
                      css={css`
                        visibility: ${checked ? 'visible' : 'hidden'};
                        border: none !important;
                        font-size: 16px;
                        background-color: transparent;
                        padding: 0;
                        border-radius: 0 !important;
                      `}
                    />
                  ) : (
                    <div
                      css={css`
                        padding-right: 16px;
                        p {
                          margin: 0;
                          padding: 0;
                          width: 100%;
                        }
                      `}
                      dangerouslySetInnerHTML={{ __html: sanitize(text) }}
                    />
                  )}
                  <Checkbox type={type} rank={rank} checked={checked} selectedRank={findIndex + 1} />
                </OptionTextWrapper>
              </ListItem>
            );
          })}
        </ListWrapper>
      )}
    </ChoiceWrapper>
  );
};

export default observer(Choice);
