import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import { css, ThemeProvider } from '@emotion/react';
import { getSharedForm } from '@/lib/ApiUtil';
import PreviewComponent from '@/components/preview/Preview';
import { useGlobalStore } from '@/hooks/useStore';
import { observer } from 'mobx-react';
import theme from '@/styled/theme';

const Preview = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { search }: { search: string } = useLocation();
  const { form, setForm } = useGlobalStore();

  const { formNo, previewToken } = parse(search);

  useEffect(() => {
    const init = async () => {
      const { data } = await getSharedForm({
        formNo: parseInt(formNo as string, 10),
        previewToken: previewToken as string,
      });
      setForm(data);
      setIsLoading(false);
    };

    void init();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (!formNo || !previewToken) {
    return <div />;
  }

  const {
    styleOptions: { buttonColor },
  } = form;

  return (
    <ThemeProvider
      theme={{
        color: buttonColor || theme.colors.primary.default,
      }}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        `}
      >
        <PreviewComponent />
      </div>
    </ThemeProvider>
  );
};

export default observer(Preview);
