import { BottomNavBar, LoadingRing, NavBarButtonWrapper, NextButton } from '@opensurvey/open-ui';
import { OPEN_ENDED_QUESTION_TYPES } from '@/constants/app';
import { useAnswerStore, useGlobalStore } from '@/hooks/useStore';
import { prependProtocol } from '@/lib/Util';
import { css, useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type NavigateProps = {
  isPreview?: boolean;
};

const Navigate = ({ isPreview = false }: NavigateProps): JSX.Element => {
  const { color } = useTheme();
  const globalStore = useGlobalStore();
  const answerStore = useAnswerStore();
  const { form, goBack } = globalStore;
  const { changeQuestion, isFirstTime, currentQuestion, currentQuestionIndex, isFinished, setCurrentQuestionIndex } =
    answerStore;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const {
    styleOptions: { outro, intro, hidePrevButton },
  } = form;

  // 시작화면에서도 진입로직을 체크하도록 함
  const { concreteQuestion, type } = currentQuestion || {};

  return (
    <BottomNavBar
      isOpenEndedQuestion={OPEN_ENDED_QUESTION_TYPES.includes(type)}
      css={css`
        padding-bottom: 32px;
        touch-action: none;
      `}
    >
      <NavBarButtonWrapper>
        {!hidePrevButton && !isFinished && !isFirstTime && (
          <div
            css={css`
              margin-right: 0.5em;
              @media only screen and (max-width: 414px) {
                width: 100%;
              }
            `}
          >
            <NextButton
              color={color}
              css={css`
                border: 1px solid ${color} !important;
                color: ${color} !important;
                background-color: #fff !important;
              `}
              onClick={() => {
                goBack();
              }}
              disabled={isLoading}
            >
              {t('이전')}
            </NextButton>
          </div>
        )}
        <div
          css={css`
            ${(isFirstTime || isFinished) &&
            css`
              margin: auto;
            `}
            @media screen and (max-width: 414px) {
              width: 100%;
              ${!hidePrevButton &&
              css`
                margin-left: 0.5em;
              `}
            }
          `}
        >
          {!isFinished && (
            <NextButton
              center={isFirstTime}
              color={color}
              disabled={(!isFirstTime && !concreteQuestion?.isAnswerValid) || isLoading}
              onClick={async () => {
                if (isFirstTime) {
                  setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                  setIsLoading(true);
                  try {
                    await changeQuestion(isPreview);
                  } catch (e) {
                    alert(t('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.'));
                    throw e;
                  } finally {
                    setIsLoading(false);
                  }
                }
              }}
            >
              {isLoading ? (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <LoadingRing width="28px" height="28px" color={'white'} thickness="4px" />
                </div>
              ) : isFirstTime ? (
                intro?.buttonText || t('시작')
              ) : (
                t('다음')
              )}
            </NextButton>
          )}
          {isFinished && outro?.showButton && (
            <NextButton
              center
              color={color}
              onClick={() => {
                if (outro?.buttonLink) {
                  window.open(prependProtocol(outro?.buttonLink), '_blank');
                }
              }}
            >
              {outro?.buttonText || t('완료')}
            </NextButton>
          )}
        </div>
      </NavBarButtonWrapper>
    </BottomNavBar>
  );
};

export default observer(Navigate);
