import { configure } from 'mobx';
import AnswerStore from './AnswerStore';
import GlobalStore from './GlobalStore';

configure({ useProxies: 'ifavailable' });
export type RootStoreSchema = RootStore;

class RootStore {
  answerStore;
  globalStore;

  constructor() {
    this.answerStore = new AnswerStore(this);
    this.globalStore = new GlobalStore(this);
  }
}

export default RootStore;
