import Navigate from '@/components/question/Navigate';
import withFormRequest from '@/hocs/withFormRequest';
import { useAnswerStore, useGlobalStore } from '@/hooks/useStore';
import { ThemeProvider } from '@emotion/react';
import Main from 'Components/Answer';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import Complete from './Complete';
import { PaddingWrapperFixed } from '@/components/preview/Preview';
import theme from '@/styled/theme';

const Answer = (): JSX.Element => {
  const { isFinished, currentQuestionIndex } = useAnswerStore();
  const {
    form: {
      styleOptions: { buttonColor },
    },
    scrollToTop,
  } = useGlobalStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
    scrollToTop();
  }, [currentQuestionIndex, isFinished]);

  return (
    <ThemeProvider
      theme={{
        color: buttonColor || theme.colors.primary.default,
      }}
    >
      <PaddingWrapperFixed ref={ref}>
        {isFinished ? <Complete /> : <Main />}
        <Navigate />
      </PaddingWrapperFixed>
    </ThemeProvider>
  );
};

export default withFormRequest(observer(Answer));
