import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

class PrivacyOtherModel {
  privacyOtherAnswer = '';

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        this.privacyOtherAnswer = answer[0].str;
      }
    }
    makeObservable(this, {
      privacyOtherAnswer: observable,

      changePrivacyOtherAnswer: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changePrivacyOtherAnswer = (value: string): void => {
    this.privacyOtherAnswer = value;
  };

  resetAnswer = (): void => {
    this.changePrivacyOtherAnswer('');
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.privacyOtherAnswer) return;
    return [{ str: this.privacyOtherAnswer }];
  }

  get isAnswerValid(): boolean {
    return this.privacyOtherAnswer.trim().length > 0;
  }
}

export default PrivacyOtherModel;
