import React, { useEffect } from 'react';
import { QuestionProps } from 'Models/Question';
import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import TextInput from '../common/TextInput';
import { PhoneRegex } from '@/constants/app';
import { PrivacyPolicyModelProps } from '@/models/PrivacyPolicyModel';
import { useGlobalStore } from '@/hooks/useStore';
import { autoFormatPhoneNumber } from '@/lib/Util';
import { B1, B2, C1 } from '@opensurvey/open-ui';
import { CircleClose } from '@/constants/icons';

interface PrivacyPolicyProps {
  question: QuestionProps;
}

const RadioWrapper = css`
  display: flex;
  align-items: center;
  margin-right: 19px;
  margin-bottom: 5px;

  span {
    position: relative;
    top: 2px;
    margin-left: 11px;
  }

  input[type='radio'],
  input[type='radio']:checked {
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 0.1rem;
  }

  input[type='radio'] {
    border: 1px solid rgba(29, 37, 45, 0.38);
    display: flex;
    background-color: #fff;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: auto;
    border-radius: 50%;
  }

  input[type='radio']:checked {
    border-color: #fb5146;
  }

  input[type='radio']:checked:before {
    background-color: #fb5146;
  }
`;

const PrivacyPolicy = ({ question }: PrivacyPolicyProps): JSX.Element => {
  const {
    concreteQuestion: {
      phoneNumber,
      changePhoneNumber,
      isPhoneNumberValid,
      agreedToPrivacyPolicy,
      setAgreedToPrivacyPolicy,
      setIsAnswerValid,
    },
  } = question as { concreteQuestion: PrivacyPolicyModelProps };
  const {
    form: {
      styleOptions: { rewardAgreement },
    },
  } = useGlobalStore();

  useEffect(() => {
    setIsAnswerValid(agreedToPrivacyPolicy !== undefined ? (agreedToPrivacyPolicy ? isPhoneNumberValid : true) : false);
  }, [isPhoneNumberValid, agreedToPrivacyPolicy]);

  return (
    <div>
      <div>
        <B1 color="#1d252d" fontWeight={700} margin="0 0 16px 0">
          개인정보 제공 동의
        </B1>
        <div
          css={css`
            background-color: #fafbfb;
            border-radius: 4px;
            padding: 3%;
            margin-bottom: 1em;
          `}
        >
          <B2 color="#1d252d">
            설문에 참여해주신 분들께 보상(모바일 쿠폰)을 제공하기 위해 아래와 같이 개인정보 제공 (취급 위탁) 동의를
            받고자 합니다.
          </B2>
          <ul
            css={css`
              padding-left: 20px;
              li {
                list-style-type: none;
                position: relative;
              }
              li:before {
                content: '•';
                position: absolute;
                left: -1em;
                font-size: 12px;
              }
            `}
          >
            <li>
              <C1>이용 목적: 설문 참여 보상 발송</C1>
            </li>
            <li>
              <C1>제공 받는자: 주식회사 케이티알파, (주) 오픈서베이</C1>
            </li>
            <li>
              <C1>제공하는 개인 정보: 휴대전화번호</C1>
            </li>
            <li>
              <C1>보유 및 이용 기간: 응답 완료일 기준 5년 후 파기</C1>
            </li>
          </ul>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 1em;
        `}
      >
        <label css={RadioWrapper}>
          <input
            type="radio"
            name="radio"
            checked={agreedToPrivacyPolicy === true}
            onClick={() => {
              setAgreedToPrivacyPolicy(true);
            }}
          />
          <span>동의함</span>
        </label>
        <label css={RadioWrapper}>
          <input
            type="radio"
            name="radio"
            checked={agreedToPrivacyPolicy === false}
            onClick={() => {
              setAgreedToPrivacyPolicy(false);
            }}
          />
          <span>동의하지 않음</span>
        </label>
      </div>
      {/* {rewardAgreement?.canContinueWithoutAgreement ? <span></span>} */}
      <div
        css={css`
          > span {
            font-size: 14px;
            color: #fb5146;
          }
          .message {
            padding: 10px 16px;
            text-align: center;
            background: #fafbfb;
            border-radius: 4px;
          }
        `}
      >
        {agreedToPrivacyPolicy ? (
          <TextInput
            placeholder={'핸드폰 번호를 입력해 주세요'}
            value={phoneNumber}
            Icon={phoneNumber ? <CircleClose /> : undefined}
            onIconClick={() => {
              changePhoneNumber('');
            }}
            warningMessage="올바른 핸드폰 번호를 입력해 주세요."
            isValid={PhoneRegex.test(phoneNumber)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              if (value.length > 13) {
                e.preventDefault();
                return;
              }
              changePhoneNumber(autoFormatPhoneNumber(value));
            }}
          />
        ) : rewardAgreement?.canContinueWithoutAgreement ? (
          <div className="message">
            <C1 color="#1d252d">
              동의하지 않아도 응답이 가능합니다. <br />
              단, 리워드 지급 대상자에서 제외됩니다.
            </C1>
          </div>
        ) : (
          <div className="message">
            <C1 color="#1d252d">동의하지 않을 경우 응답이 종료됩니다.</C1>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(PrivacyPolicy);
