import { B1, B2, H2, H4 } from '@opensurvey/open-ui';
import styled from '@emotion/styled';
import img_null_monitoring from '@/images/img_null_monitoring.png';
import React from 'react';
import { addHours, format, parseISO } from 'date-fns';
import { ko, enUS } from 'date-fns/locale';
import theme from '@/styled/theme';
import { useMediaQuery } from 'react-responsive';
import { Trans, useTranslation } from 'react-i18next';
import { useInitLanguageFromSearchParam } from '@/hooks/useInitLanguageFromSearchParam';

const CONSTRUCTION_HOURS = 4;
export const CONSTRUCTION_START_DATE = parseISO('2023-04-09 00:00:00');
export const CONSTRUCTION_END_DATE = addHours(CONSTRUCTION_START_DATE, CONSTRUCTION_HOURS);

const UnderConstruction = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 414px)' });

  const { t } = useTranslation();

  const { isLoading } = useInitLanguageFromSearchParam();

  const currentLang = document.querySelector('html')?.getAttribute('lang');

  const isKorean = currentLang === 'ko';

  const localeDateFormat = isKorean ? 'yyyy.MM.dd(E) HH:mm' : 'MMM, dd, yyyy(E) HH:mm';

  const CONSTRUCTION_PERIOD_TEXT = `${format(CONSTRUCTION_START_DATE, localeDateFormat, {
    locale: isKorean ? ko : enUS,
  })} ~ ${format(CONSTRUCTION_END_DATE, 'HH:mm')} ${isKorean ? '' : '(GMT+0900)'}`;

  if (isMobile) {
    const Header = isKorean ? H2 : H4;

    return (
      <MobileWrapper>
        <div>
          <Image width={240} src={img_null_monitoring} />
        </div>
        <Header color={theme.colors.secondary.default} margin="32px 12px 16px 12px" textAlign="center">
          {!isLoading && <Trans i18nKey={'서비스 점검중 입니다'} />}
        </Header>
        <B1 color={theme.colors.black.black_1} margin="0 12px 32px 12px" textAlign="center">
          {!isLoading && (
            <Trans i18nKey={'점검 시간 동안 설문 응답이 중단되니 점검 시간 이후 응답해주시기 바랍니다.'} />
          )}
        </B1>

        <B1 fontWeight={700} color={theme.colors.secondary.light_9}>
          {!isLoading && t('점검일시')}
        </B1>

        <B2>{CONSTRUCTION_PERIOD_TEXT}</B2>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper>
      <Left>
        <Box>
          <H2 color={theme.colors.secondary.default} margin="0 0 16px">
            {!isLoading && <Trans i18nKey={'서비스 점검중 입니다'} />}
          </H2>
          <B1 color={theme.colors.black.black_1} margin="0 0 60px">
            {!isLoading && (
              <Trans i18nKey={'점검 시간 동안 설문 응답이 중단되니 점검 시간 이후 응답해주시기 바랍니다.'} />
            )}
          </B1>

          <B1 fontWeight={700} color={theme.colors.secondary.light_9}>
            {!isLoading && t('점검일시')}
          </B1>

          <B2>{CONSTRUCTION_PERIOD_TEXT}</B2>
        </Box>
      </Left>

      <Right>
        <Image width={480} src={img_null_monitoring} />
      </Right>
    </Wrapper>
  );
};

export default UnderConstruction;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const Left = styled.div`
  background-color: white;
  box-sizing: border-box;
  width: 50%;
  @media only screen and (max-width: 1199px) {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Right = styled.div`
  @media only screen and (max-width: 1199px) {
    display: none;
  }
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  background: ${theme.colors.secondary.light_0};
  overflow: hidden;
`;

const Image = styled.img`
  margin: auto;
`;

const Box = styled.div`
  margin: auto;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
