import { observable, action, computed, makeObservable } from 'mobx';
import QuestionSchema, { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

export interface NumericModelProps {
  changeValue: (text: string) => void;
  resetAnswer: () => void;

  numberValue: string;
  unit?: string;
  minValue?: number;
  maxValue?: number;
  multipleN?: number;
  decimalPoint?: number;

  isAnswerValid: boolean;
  answer?: AnswerSchema[];
}

class NumericModel implements NumericModelProps {
  numberValue = '';

  maxValue;

  minValue;

  unit;

  multipleN;

  decimalPoint;

  constructor(question: QuestionSchema, questionAnswer?: QuestionAnswerSchema) {
    this.maxValue = question.answerOptions?.numericAnswerOptions?.maxValue;
    this.minValue = question.answerOptions?.numericAnswerOptions?.minValue;
    this.unit = question.displayOptions?.numericAnswerDisplayOptions?.unit;
    this.multipleN = question.answerOptions?.numericAnswerOptions?.multipleN;
    this.decimalPoint = question.answerOptions?.numericAnswerOptions?.decimalPoint;
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].num !== undefined) {
        this.numberValue = `${answer[0].num}`;
      }
    }
    makeObservable(this, {
      numberValue: observable,

      resetAnswer: action,
      changeValue: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  resetAnswer = (): void => {
    this.numberValue = '';
  };

  changeValue = (value: string): void => {
    this.numberValue = value;
  };

  get answer(): AnswerSchema[] | undefined {
    if (!this.numberValue) return;
    return [{ num: parseInt(this.numberValue, 10) }];
  }

  get isAnswerValid(): boolean {
    const { maxValue, minValue } = this;
    const num = Number(this.numberValue);

    if (this.numberValue === '') {
      return false;
    }

    if (!(maxValue !== undefined && minValue !== undefined)) {
      return num > 0;
    }

    return num >= minValue && num <= maxValue;
  }
}

export default NumericModel;
