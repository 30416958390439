import React from 'react';
import { QuestionProps } from 'Models/Question';
import { ShortAnswerModelProps } from 'Models/index';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { useAnswerStore } from '@/hooks/useStore';
import { CircleClose } from '@/constants/icons';
import { CustomTextInput, OpenEndedWrapper } from '@opensurvey/open-ui';
import { useTranslation } from 'react-i18next';

interface ShortAnswerProps {
  question: QuestionProps;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShortAnswer = ({ question }: ShortAnswerProps): JSX.Element => {
  const { color } = useTheme();
  const { currentQuestion } = useAnswerStore();
  const { concreteQuestion } = currentQuestion as { concreteQuestion: ShortAnswerModelProps };
  const {
    textAnswerOptions: { maxLength },
    isAnswerValid,
  } = concreteQuestion;
  const { t } = useTranslation();
  if (!concreteQuestion) {
    return <div />;
  }

  const message = t('1글자 이상 20글자 이하로 입력해 주세요.', {
    min: concreteQuestion.textAnswerOptions.minLength,
    max: concreteQuestion.textAnswerOptions.maxLength,
  });

  return (
    <OpenEndedWrapper>
      <CustomTextInput
        borderColor={color}
        placeholder={t('답변을 입력해 주세요')}
        value={concreteQuestion.text}
        Icon={concreteQuestion.text.length > 0 ? <CircleClose /> : null}
        onIconClick={() => {
          concreteQuestion.changeText('');
        }}
        helperMessage={message}
        warningMessage={message}
        limitMessage={`${concreteQuestion.text.length}/${maxLength}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          concreteQuestion.changeText(value);
        }}
        isValid={isAnswerValid}
      />
    </OpenEndedWrapper>
  );
};

export default observer(ShortAnswer);
