import React from 'react';
import { QuestionProps } from 'Models/Question';
import TableLikert from '@/components/common/TableTypeLikert';
import { LikertModelProps } from '@/models';
import VerticalLikert from '../common/VerticalLikert';
import HorizontalLikert10 from './HorizontalLikert10';

interface LikertProps {
  question: QuestionProps;
}

const Likert = ({ question }: LikertProps): JSX.Element => {
  const { concreteQuestion } = question;
  if (!concreteQuestion) {
    return <div />;
  }
  const {
    evaluationDisplayOptions: { horizontal },
    evaluationAnswerOptions: { level },
  } = concreteQuestion as LikertModelProps;

  if (horizontal && level > 7) {
    return <HorizontalLikert10 question={question} />;
  }

  return <div>{horizontal ? <TableLikert question={question} /> : <VerticalLikert question={question} />}</div>;
};

export default Likert;
