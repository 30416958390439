import { RANK_NONE } from '@/constants/app';
import { Nullable } from '@/schemas/SharedSchema';
import { observable, action, computed, makeObservable } from 'mobx';
import QuestionSchema, { QuestionOptionSchema, QuestionAnswerSchema, AnswerSchema } from 'Schemas/QuestionSchema';

export interface LikertModelProps {
  selectedIndex: number;
  changeSelection: (index: number) => void;
  resetAnswer: () => void;
  displayOptions: QuestionOptionSchema;
  evaluationDisplayOptions: {
    evalTexts: Nullable<string[]>;
    horizontal: boolean;
  };
  evaluationAnswerOptions: {
    level: number;
  };
  isAnswerValid: boolean;
  answer?: AnswerSchema[];
}
class LikertModel implements LikertModelProps {
  selectedIndex = -1;

  displayOptions = {};

  evaluationDisplayOptions = {
    evalTexts: null as Nullable<string[]>,
    horizontal: true,
  };

  evaluationAnswerOptions = {
    level: 0,
  };

  constructor(question: QuestionSchema, questionAnswer?: QuestionAnswerSchema) {
    const { displayOptions, answerOptions } = question;
    if (answerOptions) {
      const { evaluationAnswerOptions } = answerOptions;
      this.evaluationAnswerOptions = evaluationAnswerOptions as {
        level: number;
      };
    }
    if (displayOptions) {
      const { evaluationDisplayOptions } = displayOptions;
      this.evaluationDisplayOptions = evaluationDisplayOptions as {
        evalTexts: Nullable<string[]>;
        horizontal: boolean;
      };
    }

    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer[0] && answer[0].num !== undefined) {
        this.selectedIndex = answer[0].num;
      }
    }

    makeObservable(this, {
      selectedIndex: observable,

      changeSelection: action,
      resetAnswer: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeSelection = (index: number): void => {
    this.selectedIndex = index;
  };

  resetAnswer = (): void => {
    this.selectedIndex = -1;
  };

  get answer(): AnswerSchema[] | undefined {
    if (this.selectedIndex === RANK_NONE) return;
    return [{ num: this.selectedIndex }];
  }

  get isAnswerValid(): boolean {
    return this.selectedIndex >= 0;
  }
}

export default LikertModel;
