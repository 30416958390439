import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import RootStore from 'Stores/RootStore';
import { CookiesProvider } from 'react-cookie';
import StoreProvider from './contexts/Context';
import { BrowserRouter as Router } from 'react-router-dom';
import { Global, ThemeProvider } from '@emotion/react';
import theme, { GlobalStyle } from '@/styled/theme';
import DOMPurify from 'isomorphic-dompurify';

const rootStore = new RootStore();

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      return;
    }

    const targetAttributeValue = node.getAttribute('target');

    if (node.hasAttribute('target') && targetAttributeValue === '_blank') {
      node.setAttribute(TEMPORARY_ATTRIBUTE, targetAttributeValue);
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  const targetAttributeValue = node.getAttribute(TEMPORARY_ATTRIBUTE);
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE) && targetAttributeValue) {
    node.setAttribute('target', targetAttributeValue);
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <StoreProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <Provider {...rootStore}>
              <Global styles={GlobalStyle} />
              <App />
            </Provider>
          </ThemeProvider>
        </Router>
      </StoreProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
