import { action, computed, makeObservable, observable } from 'mobx';
import { AnswerSchema, QuestionAnswerSchema } from 'Schemas/QuestionSchema';

type Address = {
  postalCode: string;
  roadAddress: string;
  detailedAddress: string;
};

class AddressModel {
  private readonly initialAddress = {
    postalCode: '',
    roadAddress: '',
    detailedAddress: '',
  };

  address: Address = this.initialAddress;

  constructor(questionAnswer?: QuestionAnswerSchema) {
    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0 && answer[0].str) {
        const [postalCode, roadAddress, detailedAddress] = answer[0].str.split(' | ');

        this.address.postalCode = postalCode;
        this.address.roadAddress = roadAddress;
        this.address.detailedAddress = detailedAddress;
      }
    }
    makeObservable(this, {
      address: observable,

      changeAddress: action,

      answer: computed,
      isAnswerValid: computed,
    });
  }

  changeAddress = (value: Partial<Address>): void => {
    this.address = {
      ...this.address,
      ...value,
    };
  };

  resetAnswer = (): void => {
    this.changeAddress(this.initialAddress);
  };

  get fullAddress() {
    const { postalCode, roadAddress, detailedAddress } = this.address;
    const fullAddress = [postalCode, roadAddress, detailedAddress].join(' | ');

    return fullAddress;
  }

  get answer(): AnswerSchema[] | undefined {
    if (!this.address) return;

    return [{ str: this.fullAddress }];
  }

  get isAnswerValid(): boolean {
    const { postalCode, roadAddress } = this.address;
    return [postalCode, roadAddress].every((v) => v.length);
  }
}

export default AddressModel;
