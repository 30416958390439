import { CustomTextInput, OpenEndedWrapper } from '@opensurvey/open-ui';
import { NumberRegex } from '@/constants/app';
import { NumericModelProps } from '@/models/NumericModel';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { QuestionProps } from 'Models/Question';
import React from 'react';
import { numberWithCommas } from '@/lib/numberWithCommas';
import { useTranslation } from 'react-i18next';

interface NumericProps {
  question: QuestionProps;
}

const Numeric = ({ question }: NumericProps): JSX.Element => {
  const { color } = useTheme();
  const {
    concreteQuestion,
    concreteQuestion: { changeValue, unit },
  } = question as { concreteQuestion: NumericModelProps };
  const { t } = useTranslation();
  if (!concreteQuestion) {
    return <div />;
  }

  const message = t('1 이상 1,000 이하의 숫자로 입력해 주세요.', {
    min: numberWithCommas(concreteQuestion.minValue),
    max: numberWithCommas(concreteQuestion.maxValue),
  });

  return (
    <OpenEndedWrapper>
      <CustomTextInput
        type="number"
        key={question.questionNo}
        placeholder={t('답변을 입력해 주세요')}
        min={concreteQuestion.minValue}
        max={concreteQuestion.maxValue}
        step={0.0001}
        value={concreteQuestion.numberValue}
        unit={unit}
        isValid={concreteQuestion.isAnswerValid}
        helperMessage={message}
        warningMessage={message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          changeValue(value);
        }}
        onKeyDown={(e) => {
          if (!NumberRegex.test(e.key)) {
            return;
          }

          const [, decimal] = e.currentTarget.value.split('.');

          if (!decimal) {
            return;
          }

          if (decimal.length >= 4) {
            e.preventDefault();
            return;
          }
        }}
        borderColor={color}
      />
    </OpenEndedWrapper>
  );
};

export default observer(Numeric);
