/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type ParsedLogic = {
  type: 'enterLogic' | 'action:conditional' | 'action:ternary' | 'action';
  enterLogic: Term | Operation | BinaryOperation;
  success?: Array<Action>;
  failure?: Array<Action>;
  actions?: Array<Action>;
};

export type Term = {
  nodeType: 'term';
  type: 'answerNo' | 'onlyAnswerNo' | 'answer' | 'cnt' | 'str' | 'num' | 'rankedAnswerNo' | 'multipleAnswer';
  questionNo: number;
  answerNo?: number;
  answerList?: number[];
  rankedAnswerNo?: number;
  complement?: boolean;
  group?: number;
};

export type Group = {
  nodeType: 'group';
  data: Term | Operation | BinaryOperation | RangeOperation;
};

export function instanceOfTerm(object: any): object is Term {
  return (object as Term).nodeType === 'term';
}

export type BinaryOperation = {
  nodeType: 'binaryOperation';
  op: 'and' | 'or';
  left: Term | BinaryOperation | Operation;
  right: Term | BinaryOperation | Operation;
  complement?: boolean;
  group?: number;
};

export function instanceOfBinaryOperation(object: any): object is BinaryOperation {
  return (object as BinaryOperation).nodeType === 'binaryOperation';
}

export type Operation = {
  nodeType: 'operation';
  op: '<' | '<=' | '>' | '>=' | '==' | 'like';
  left: Term;
  right: number | string;
  complement?: boolean;
  group?: number;
};

export function instanceOfOperation(object: any): object is Operation {
  return (object as Operation).nodeType === 'operation';
}

export type RangeOperation = {
  nodeType: 'rangeOperation';
  lower: number | string;
  upper: number | string;
  value: Term;
  complement?: boolean;
  group?: number;
};

export function instanceOfRangeOperation(object: any): object is RangeOperation {
  return (object as RangeOperation).nodeType === 'rangeOperation';
}

export type Action = {
  type: string;
  list?: Array<number | string>;
  filter?: string;
  questionNo?: number;
  delay?: number;
  term?: Term;
};
