import RootStore, { RootStoreSchema } from '@/stores/RootStore';
import React, { createContext } from 'react';

type StoreProviderProps = {
  children: React.ReactNode;
};

export const StoreContext = createContext<RootStoreSchema | null>(null);

export const StoreProvider = ({ children }: StoreProviderProps): JSX.Element => {
  const store = new RootStore();

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
