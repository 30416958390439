import { DEFAULT_LANG } from '@/i18n/config';
import FormSchema, { FormMetaSchema, StyleOptionsSchema } from '@/schemas/FormSchema';
import { computed, makeObservable } from 'mobx';

export interface FormModel extends Omit<FormSchema, 'questions'> {
  isRewardToggled: boolean;
}

class Form implements FormModel {
  formNo = 0;

  title = '';

  titleForPanel = '';

  styleOptions = {} as StyleOptionsSchema;

  type = '';

  startedAt;

  finishedAt;

  initialStartedAt;

  responseCacheExpiredAt;

  createdAt;

  updatedAt;

  formMeta = {} as FormMetaSchema;

  lang;

  constructor(form: FormSchema) {
    const {
      formNo,
      title,
      titleForPanel,
      type,
      startedAt,
      finishedAt,
      initialStartedAt,
      responseCacheExpiredAt,
      createdAt,
      updatedAt,
      styleOptions,
      formMeta,
      lang,
    } = form;
    this.formNo = formNo;
    this.title = title;
    this.titleForPanel = titleForPanel;
    this.type = type;
    this.startedAt = startedAt;
    this.finishedAt = finishedAt;
    this.initialStartedAt = initialStartedAt;
    this.responseCacheExpiredAt = responseCacheExpiredAt;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.styleOptions = styleOptions;
    this.formMeta = formMeta;
    this.lang = lang || DEFAULT_LANG;

    makeObservable(this, {
      isRewardToggled: computed,
    });
  }

  get isRewardToggled(): boolean {
    const {
      styleOptions: { rewardAgreement },
    } = this;

    return rewardAgreement !== null && rewardAgreement !== undefined;
  }
}

export default Form;
