import { CHOICE_QUESTION_TYPES, QUESTION_TYPE } from '@/constants/app';
import { ChoiceModelProps } from '@/models';
import { getRealOptionList } from '@/models/ChoiceModel';
import { AnswerSchema, QuestionAnswerSchema } from '@/schemas/QuestionSchema';
import { postAnswer } from 'Lib/ApiUtil';
import { action, computed, makeObservable, observable } from 'mobx';
import { QuestionProps } from 'Models/Question';
import { RootStoreSchema } from './RootStore';

export type SubmittedAnswers = Array<{ answers: Array<{ str?: string; num?: number }>; questionIndex: number }>;
export type AnswerStoreSchema = AnswerStore;

class AnswerStore {
  readonly initialQuestionIndex = -1;

  rootStore;

  questions = [] as Array<QuestionProps>;

  currentQuestionIndex: number;

  submittedPreviewAnswers = [];

  isFinished = false;

  ignoreLogic = false;

  constructor(rootStore: RootStoreSchema) {
    this.rootStore = rootStore;
    makeObservable(this, {
      questions: observable.ref,
      currentQuestionIndex: observable,
      submittedPreviewAnswers: observable.ref,
      isFinished: observable,
      ignoreLogic: observable,

      simulateAnswers: action,
      changeQuestion: action,
      setCurrentQuestionIndex: action,
      setIsFinished: action,

      isFirstTime: computed,
      currentQuestion: computed,
    });

    this.currentQuestionIndex = this.initialQuestionIndex;
  }

  simulateAnswers = (): void => {
    // answer 모음이 존재한다면, index 이동
    const { globalStore } = this.rootStore;
    const { questions } = globalStore;
    const totalQuestionCount = questions.length;

    if (globalStore.answers.length) {
      this.setCurrentQuestionIndex(0);
    }

    questions.forEach((question: QuestionProps, index) => {
      const { concreteQuestion, skipToQuestionNo, type, questionNo } = question;
      const { answer, isAnswerValid } = concreteQuestion as {
        answer: Array<{ num: number; str: string }>;
        isAnswerValid: boolean;
      };

      if (CHOICE_QUESTION_TYPES.includes(type)) {
        const { setRealOptions } = concreteQuestion as ChoiceModelProps;
        setRealOptions(getRealOptionList({ questions, question }));
      }

      if (answer && answer.length > 0 && isAnswerValid) {
        //option의 문항 이동 로직 우선 체크
        if (
          type === QUESTION_TYPE.SINGLE_CHOICE ||
          type === QUESTION_TYPE.MULTIPLE_CHOICE ||
          type === QUESTION_TYPE.RANK
        ) {
          const { options } = concreteQuestion as ChoiceModelProps;
          const selectedOption = options.find((option) => option.rank === answer[0].num);

          if (selectedOption?.skipToQuestionNo && selectedOption?.skipToQuestionNo > 0) {
            // 파이핑 문항인 경우 무시
            if (selectedOption.questionNo !== questionNo) {
              this.setCurrentQuestionIndex(this.currentQuestionIndex + 1);
              return;
            }

            const destinationIndex = questions.findIndex(
              (question: QuestionProps) => question.questionNo === selectedOption?.skipToQuestionNo
            );
            this.currentQuestionIndex = destinationIndex;
            return;
          }
        }

        //question 문항 이동 로직 체크
        if (skipToQuestionNo) {
          const destinationIndex = questions.findIndex(
            (question: QuestionProps) => question.questionNo === skipToQuestionNo
          );
          this.setCurrentQuestionIndex(destinationIndex);
          return;
        }

        //문항 이동 로직 없을 경우
        if (this.currentQuestionIndex < totalQuestionCount - 1) {
          this.setCurrentQuestionIndex(index + 1);
          return;
        }
      }
    });
  };

  setIsFinished = (isFinished: boolean): void => {
    this.isFinished = isFinished;
  };

  setCurrentQuestionIndex = (index: number): void => {
    this.currentQuestionIndex = index;
    this.rootStore.globalStore.scrollToTop();
  };

  submitAnswer = async (
    answer: AnswerSchema[],
    isPreview = false,
    questionNo: number,
    /**
     * destinationIndex < 0 => finish
     * destinationIndex > 0 => skip
     * destinationIndex == 0 => next
     */
    destinationIndex: number
  ): Promise<void> => {
    const { globalStore } = this.rootStore;
    const { questions, answerToken } = globalStore;

    if (destinationIndex < 0) {
      if (!isPreview) {
        await postAnswer('true', {
          answerToken,
          questionNo: questionNo,
          answer: answer,
        });
      }
      this.setIsFinished(true);
    } else if (destinationIndex === 0) {
      if (!isPreview) {
        await postAnswer('false', {
          answerToken,
          questionNo: questionNo,
          answer: answer,
        });
      }
      if (questions.length - 1 > this.currentQuestionIndex) {
        this.setCurrentQuestionIndex(this.currentQuestionIndex + 1);
      }
    } else {
      if (!isPreview) {
        await postAnswer('false', {
          answerToken,
          questionNo: questionNo,
          answer: answer,
        });
      }
      this.setCurrentQuestionIndex(destinationIndex);
    }

    this.rootStore.globalStore.answers.push({
      questionNo,
      answer: answer,
    } as QuestionAnswerSchema);
  };

  changeQuestion = async (isPreview = false): Promise<void> => {
    const { globalStore } = this.rootStore;
    const { questions } = globalStore;
    const { concreteQuestion, type, skipToQuestionNo, questionNo } = this.currentQuestion;

    const { answer } = concreteQuestion;

    if (!answer) {
      return;
    }

    //객관식 문항 분기 처리
    if (type === QUESTION_TYPE.SINGLE_CHOICE || type === QUESTION_TYPE.MULTIPLE_CHOICE || type === QUESTION_TYPE.RANK) {
      //객관식 문항일 때 보기별 응답 후 문항 이동 처리
      const selectedOption = (concreteQuestion as ChoiceModelProps).options.find(
        (option) => option.rank === (concreteQuestion as ChoiceModelProps).answer[0].num
      );

      // 객관식 파이핑된 문항의 경우 skipToQuestionNo를 무시한다
      if (selectedOption?.questionNo !== questionNo) {
        if (questions.length - 1 > this.currentQuestionIndex) {
          await this.submitAnswer(answer, isPreview, questionNo, 0);
        } else {
          await this.submitAnswer(answer, isPreview, questionNo, -1);
        }
        return;
      }

      if (selectedOption?.skipToQuestionNo && selectedOption?.skipToQuestionNo < 0) {
        await this.submitAnswer(answer, isPreview, questionNo, selectedOption?.skipToQuestionNo);
        return;
      }

      if (selectedOption?.skipToQuestionNo && selectedOption?.skipToQuestionNo > 0) {
        const destinationIndex = questions.findIndex(
          (question: QuestionProps) => question.questionNo === selectedOption?.skipToQuestionNo
        );
        await this.submitAnswer(answer, isPreview, questionNo, destinationIndex);
        return;
      }
    }

    //개인정보 수집 동의 문항 분기처리
    if (type === QUESTION_TYPE.PRIVACY_POLICY) {
      const { isPhoneNumberValid } = concreteQuestion as { isPhoneNumberValid: boolean };
      if (skipToQuestionNo && skipToQuestionNo < 0 && !isPhoneNumberValid) {
        await this.submitAnswer(answer, isPreview, questionNo, -1);
        return;
      }
      await this.submitAnswer(answer, isPreview, questionNo, 0);
      return;
    }

    //응답 후 완료 화면으로 이동
    if (skipToQuestionNo && skipToQuestionNo < 0) {
      await this.submitAnswer(answer, isPreview, questionNo, skipToQuestionNo);
      return;
    }

    //응답 후 이동 문항 존재
    if (skipToQuestionNo && skipToQuestionNo > 0) {
      const destinationIndex = questions.findIndex(
        (question: QuestionProps) => Number(question.questionNo) === this.currentQuestion.skipToQuestionNo
      );
      await this.submitAnswer(answer, isPreview, questionNo, destinationIndex);
      return;
    }

    if (questions.length - 1 > this.currentQuestionIndex) {
      await this.submitAnswer(answer, isPreview, questionNo, 0);
    } else {
      await this.submitAnswer(answer, isPreview, questionNo, -1);
    }
  };

  get currentQuestion(): QuestionProps {
    const {
      rootStore: {
        globalStore: { questions },
      },
    } = this;
    return questions[this.currentQuestionIndex];
  }

  get isFirstTime(): boolean {
    return this.currentQuestionIndex === -1;
  }
}

export default AnswerStore;
