import { StarScaleModelProps } from '@/models/StarScaleModel';
import { css, useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { QuestionProps } from 'Models/Question';
import React, { useState } from 'react';
import EvalText from '../common/EvalText';
import { Star, StarButton, StarButtonWrapper, StarScaleEvalTextWrapper, StarScaleWrapper } from '@opensurvey/open-ui';
import { useMediaQuery } from 'react-responsive';

interface StarScaleProps {
  question: QuestionProps;
}

const StarScale = ({ question }: StarScaleProps): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 414px)' });
  const { color } = useTheme();
  const {
    concreteQuestion,
    concreteQuestion: {
      evaluationAnswerOptions: { level },
      evaluationDisplayOptions: { evalTexts },
      changeSelection,
      selectedIndex,
    },
  } = question as { concreteQuestion: StarScaleModelProps };
  const [hoverIndex, setHoverIndex] = useState(-2);
  if (!concreteQuestion) {
    return <div />;
  }

  const dummy = new Array(level);
  for (let i = 1; i <= level; i = i + 1) {
    dummy[i] = i;
  }

  return (
    <StarScaleWrapper>
      <StarButtonWrapper>
        {dummy.map((val) => (
          <StarButton
            key={val}
            onClick={() => {
              changeSelection(val);
              setHoverIndex(-1);
            }}
            onMouseOver={() => {
              if (hoverIndex !== -1 && selectedIndex === hoverIndex) {
                return;
              }
              setHoverIndex(val);
            }}
            onMouseOut={() => {
              setHoverIndex(-1);
            }}
          >
            <Star
              type={isMobile ? 'mobile' : 'desktop'}
              color={color}
              selected={selectedIndex >= val && hoverIndex === -1}
              shouldFillHover={hoverIndex >= val}
            />
          </StarButton>
        ))}
      </StarButtonWrapper>
      {evalTexts && (
        <StarScaleEvalTextWrapper
          css={css`
            > * {
              flex: 1;
            }
          `}
        >
          <EvalText text={evalTexts[0]} align="start" />
          <EvalText text={evalTexts[1]} align="end" />
        </StarScaleEvalTextWrapper>
      )}
    </StarScaleWrapper>
  );
};

export default observer(StarScale);
