import { TEXT_NEUTRAL_DISPLAY_DISABLE_LEVEL } from '@/constants/app';
import { useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { LikertModelProps } from 'Models/LikertModel';
import { QuestionProps } from 'Models/Question';
import React from 'react';
import EvalText from './EvalText';
import {
  HorizontalLikertWrapper,
  HorizontalLikertLevelWrapper,
  HorizontalLikertBar,
  HorizontalLikertLevel,
  ChoiceOption,
  HorizontalLikertEvalTextWrapper,
} from '@opensurvey/open-ui';

interface LikertProps {
  question: QuestionProps;
}

const TableLikert = ({ question }: LikertProps) => {
  const { color } = useTheme();
  const { concreteQuestion } = question;
  if (!concreteQuestion) {
    return <div />;
  }

  const { evaluationDisplayOptions, evaluationAnswerOptions, changeSelection, selectedIndex } =
    concreteQuestion as LikertModelProps;
  const { evalTexts } = evaluationDisplayOptions;
  const { level } = evaluationAnswerOptions;

  const levels = [];
  for (let i = 1; i <= level; ++i) {
    levels.push(i);
  }

  const isLevelEven = level % 2 === 0;

  return (
    <HorizontalLikertWrapper>
      <HorizontalLikertLevelWrapper>
        <HorizontalLikertBar color={color} />
        {levels.map((val, i) => {
          const id = `likert_option_${i}`;
          return (
            <HorizontalLikertLevel key={id}>
              <ChoiceOption
                tabIndex={i + 1}
                color={color}
                selected={selectedIndex === val}
                onClick={() => {
                  changeSelection(val);
                }}
              />
            </HorizontalLikertLevel>
          );
        })}
      </HorizontalLikertLevelWrapper>
      {evalTexts && (
        <HorizontalLikertEvalTextWrapper>
          <EvalText text={evalTexts[0]} align="start" />
          {level !== TEXT_NEUTRAL_DISPLAY_DISABLE_LEVEL && <EvalText text={evalTexts[1]} align="center" />}
          <EvalText text={isLevelEven ? evalTexts[1] : evalTexts[2]} align="end" />
        </HorizontalLikertEvalTextWrapper>
      )}
    </HorizontalLikertWrapper>
  );
};

export default observer(TableLikert);
