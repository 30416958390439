/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { logicParser } from '@opensurvey/al-parser';
import { QuestionWrapper } from '@opensurvey/open-ui';
import { useAnswerStore, useGlobalStore } from '@/hooks/useStore';
import { evaluateNode } from '@/lib/Util';
import { observer } from 'mobx-react';
import nearley from 'nearley';
import React, { useEffect, useRef } from 'react';
import InOutro from './InOutro';
import Question from './question/Question';
import { Cookies } from 'react-cookie';
import { logError } from '@/lib/ApiUtil';
import { LOG_LEVEL } from '@/constants/app';
import { useTranslation } from 'react-i18next';

type Props = { isPreview?: boolean };

const Answer = ({ isPreview }: Props): JSX.Element => {
  const globalStore = useGlobalStore();
  const answerStore = useAnswerStore();
  const ref = useRef<HTMLDivElement>(null);
  const { questions, answers, formNo, goToQuestion } = globalStore;
  const { isFirstTime, currentQuestion, setCurrentQuestionIndex, setIsFinished, submitAnswer, currentQuestionIndex } =
    answerStore;
  const cookies = new Cookies();
  const { t } = useTranslation();

  const resubmitAnswerAndComplete = async (isPreview: boolean): Promise<void> => {
    const answerToResubmit = answers[answers.length - 1];
    try {
      //해당 문항에 진입 불가할 시 마지막 응답 재제출
      if (!isPreview) {
        // 모든 문항이 숨김인 경우 핸들
        if (answerToResubmit) {
          await submitAnswer(answerToResubmit.answer, isPreview, answerToResubmit.questionNo, -1);
        }
      }
      setIsFinished(true);
    } catch (error) {
      if (answerToResubmit) {
        goToQuestion(answerToResubmit.questionNo);
      }
      alert(t('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.'));

      if (error instanceof Error) {
        const errorMessage = `[Frontend error] ${error.message}
        url: ${window.location.href}
        stack: ${error.stack ?? '-'}
        `;
        void logError(errorMessage, LOG_LEVEL.ERROR);
      }
    }
  };

  useEffect(() => {
    const checkLogic = async (): Promise<void> => {
      if (cookies.get('skipLogic') === 'true') {
        cookies.set('skipLogic', 'false');
        return;
      }
      if (currentQuestionIndex === undefined || currentQuestionIndex === null || currentQuestionIndex < 0) {
        return;
      }

      const question = questions[currentQuestionIndex];

      if (question.isHidden) {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          await resubmitAnswerAndComplete(isPreview || false);
        }
        return;
      }

      try {
        const parser = new nearley.Parser(nearley.Grammar.fromCompiled(logicParser));
        try {
          parser.feed(questions[currentQuestionIndex].logic || '');
        } catch (error) {
          throw new LogicParsingError(questions[currentQuestionIndex].logic);
        }
        const { results } = parser;

        const shouldEnter = evaluateNode(results[0][0].enterLogic, answers);

        if (shouldEnter === false) {
          //응답 전 로직 검사
          const failureAction = results[0][0].failure;
          if (!failureAction) {
            if (currentQuestionIndex < questions.length - 1) {
              setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
              //해당 문항에 진입 불가할 시 마지막 응답 재제출
              await resubmitAnswerAndComplete(isPreview || false);
            }
            return;
          }
          //응답 전 로직 불만족 시 action 여부에 따라 handling
          if (failureAction[0]) {
            const destinationIndex = questions.findIndex(
              (question) => Number(question.questionNo) === failureAction[0].questionNo
            );
            setCurrentQuestionIndex(destinationIndex);
          } else {
            if (currentQuestionIndex < questions.length - 1) {
              setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
              await resubmitAnswerAndComplete(isPreview || false);
              return;
            }
          }
          return;
        }
      } catch (error) {
        if (error instanceof LogicParsingError) {
          //기존 parsing error 는 nearley 문법이 다 노출되기에 가공해서 log
          const currentQuestion = questions[currentQuestionIndex];

          void logError(
            `[Frontend Error] Logic Parsing Error
              url: ${window.location.href}
              logic: ${currentQuestion.logic}
              questionNo: ${currentQuestion.questionNo}
              formNo: ${formNo}
          `,
            LOG_LEVEL.ERROR
          );
          return;
        }

        const errorMessage = `[Frontend error] Unexpected Error
        url: ${window.location.href}
        stack: ${JSON.stringify(error)}
        `;
        void logError(errorMessage, LOG_LEVEL.ERROR);
      }
    };

    void checkLogic();
  }, [currentQuestionIndex, isPreview]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [currentQuestionIndex]);

  if (currentQuestionIndex !== -1 && !currentQuestion) {
    return <div>{t('잘못된 설문입니다.')}</div>;
  }

  return <QuestionWrapper ref={ref}>{isFirstTime ? <InOutro type="intro" /> : <Question />}</QuestionWrapper>;
};

class LogicParsingError extends Error {
  constructor(logic: string) {
    super(`Parsing Error: ${logic}`);
  }
}

export default observer(Answer);
