import { css, useTheme } from '@emotion/react';
import { observer } from 'mobx-react';
import { EssayModelProps } from 'Models/index';
import { QuestionProps } from 'Models/Question';
import React, { useState } from 'react';
import { EssayWrapper, TextAreaWrapper, TextAreaHelperTextWrapper, TextArea } from '@opensurvey/open-ui';
import { useTranslation } from 'react-i18next';

interface EssayProps {
  question: QuestionProps;
}

const Essay = ({ question }: EssayProps): JSX.Element => {
  const { color } = useTheme();
  const [focused, setFocused] = useState(true);
  const {
    concreteQuestion: { text, maxLength },
    concreteQuestion,
  } = question as { concreteQuestion: EssayModelProps };
  const { t } = useTranslation();
  if (!concreteQuestion) {
    return <div />;
  }

  return (
    <EssayWrapper>
      <TextAreaWrapper>
        <TextArea
          value={concreteQuestion.text}
          css={css`
            height: 100%;
            ::placeholder {
              color: rgba(29, 37, 45, 0.38);
            }
          `}
          placeholder={t('답변을 입력해 주세요')}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = e.target;
            concreteQuestion.changeText(value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          color={color}
        />
      </TextAreaWrapper>
      <TextAreaHelperTextWrapper>
        <span
          css={css`
            color: rgba(29, 37, 45, 0.6);
            visibility: ${focused ? 'visible' : 'hidden'};
          `}
        >
          {t('1글자 이상 20글자 이하로 입력해 주세요.', {
            min: concreteQuestion.minLength,
            max: concreteQuestion.maxLength,
          })}
        </span>
        <span>
          {text.length}/{maxLength}
        </span>
      </TextAreaHelperTextWrapper>
    </EssayWrapper>
  );
};

export default observer(Essay);
