import { css } from '@emotion/react';

type BaseTheme = {
  colors: Record<string, Record<string, string>>;
};

const makeTheme = <T extends BaseTheme>(theme: T) => theme;

const theme = makeTheme({
  breakpoints: [768, 1200] as const,
  color: '#fb5146',
  colors: {
    primary: {
      default: '#fb5146',
      dark_1: '#d94437',
      error: '#9b0001',
      light_8: '#fc6b62',
      light_7: '#fc857e',
      light_5: '#fda8a3',
      light_3: '#fecbc7',
      light_2: '#fedcda',
      light_1: '#fee5e3',
      light_0: '#fff6f6',
    },
    secondary: {
      default: '#626ffc',
      dark_2: '#3e469a',
      dark_1: '#5762d3',
      light_9: '#7782f7',
      light_8: '#8b95f8',
      light_7: '#a0a7f9',
      light_5: '#bbc0fb',
      light_3: '#D4D8FF',
      light_2: '#e4e6fd',
      light_1: '#ebecfe',
      light_0: '#f1f3fe',
    },
    tertiary: {
      default: '#45b3be',
      dark_2: '#00808d',
      dark_1: '#01a1b1',
      light_9: '#68b5bd',
      light_8: '#7fc0c7',
      light_7: '#95cbd1',
      light_5: '#b3dade',
      light_2: '#e1f0f2',
      light_1: '#e8f4f5',
      light_0: '#f0f8f8',
    },
    quaternary: {
      default: '#ffaf39',
      dark_2: '#ec7f00',
      dark_1: '#fc9d11',
      light_9: '#f5bc68',
      light_8: '#f7c67f',
      light_7: '#f7cd8e',
      light_5: '#faddb3',
      light_2: '#fdf2e1',
      light_1: '#fef8f0',
    },
    black: {
      black_1: 'rgba(29, 37, 45, 1)',
      black_2: 'rgba(29, 37, 45, 0.87)',
      black_3: 'rgba(29, 37, 45, 0.6)',
      black_4: 'rgba(29, 37, 45, 0.38)',
    },
    grey: {
      grey_1: '#8e9296',
      grey_2: '#b0b3b5',
      grey_3: '#d2d3d5',
      grey_4: '#e8e9ea',
      grey_5: '#f1f2f2',
      grey_6: '#f6f6f7',
      grey_7: '#fafbfb',
    },
    white: {
      white_1: 'rgba(2, 1, 1, 0.98)',
      white_2: 'rgba(255, 255, 255, 0.6)',
      white_3: 'rgba(255, 255, 255, 0.38)',
    },
    blackIcon: {
      black_1: '#1d252d',
      black_2: '#3a4148',
      black_3: '#777c81',
      black_4: '#a9acaf',
    },
  },
});

export type FeedbackTheme = typeof theme;

export const MAIN_FONT = 'Pretendard';

export const GlobalStyle = css`
  @font-face {
    font-family: ${MAIN_FONT};
    font-weight: 100;
    src: url('/fonts/Pretendard-Thin.subset.woff2') format('woff2'),
      url('/fonts/Pretendard-Thin.subset.woff') format('woff');
  }

  @font-face {
    font-family: ${MAIN_FONT};
    font-weight: 300;
    src: url('/fonts/Pretendard-Light.subset.woff2') format('woff2'),
      url('/fonts/Pretendard-Light.subset.woff') format('woff');
  }

  @font-face {
    font-family: ${MAIN_FONT};
    font-weight: 400;
    src: url('/fonts/Pretendard-Regular.subset.woff2') format('woff2'),
      url('/fonts/Pretendard-Regular.subset.woff') format('woff');
  }

  @font-face {
    font-family: ${MAIN_FONT};
    font-weight: 500;
    src: url('/fonts/Pretendard-Medium.subset.woff2') format('woff2'),
      url('/fonts/Pretendard-Medium.subset.woff') format('woff');
  }

  @font-face {
    font-family: ${MAIN_FONT};
    font-weight: 700;
    src: url('/fonts/Pretendard-Bold.subset.woff2') format('woff2'),
      url('/fonts/Pretendard-Bold.subset.woff') format('woff');
  }

  @font-face {
    font-family: 'Lexend';
    font-weight: 400;
    src: url('/fonts/Lexend-Regular.woff2') format('woff2'), url('/fonts/Lexend-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Lexend';
    font-weight: 600;
    src: url('/fonts/Lexend-Semi-bold.woff2') format('woff2'), url('/fonts/Lexend-Semi-bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Lexend';
    font-weight: 700;
    src: url('/fonts/Lexend-Bold.woff2') format('woff2'), url('/fonts/Lexend-Bold.woff') format('woff');
  }

  a {
    color: ${theme.colors.black.black_3};
  }

  * {
    font-family: Arial, sans-serif;
    box-sizing: border-box;
  }

  html {
    height: 100%;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    overflow: hidden;
  }

  body {
    margin: 0px;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: ${theme.colors.grey.grey_4};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.grey.grey_3};
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: ${theme.colors.grey.grey_2};
  }

  img {
    image-rendering: -webkit-optimize-contrast;

    /* iphone에서 이미지 길게 누를 때 컨텍스트 메뉴 나오지 않도록
    https://stackoverflow.com/questions/3831707/disable-the-save-image-menu-on-iphone-with-javascript
    */
    -webkit-touch-callout: none;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
    word-break: break-all;
  }

  :lang(ko) {
    font-family: ${MAIN_FONT}, sans-serif;
  }
  :lang(ja) {
    font-family: Meiryo, Arial, sans-serif;
  }
  :lang(zh-CN) {
    font-family: 'Microsoft Yahei', Arial, sans-serif;
  }
  :lang(zh-TW) {
    font-family: 'Microsoft JhengHei', Arial, sans-serif;
  }
  :lang(th) {
    font-family: Tahoma, Arial, sans-serif;
  }
`;

export default theme;
