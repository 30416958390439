import { QUESTION_TYPE, RANK_NONE, RANK_OTHERS } from '@/constants/app';
import { shuffleArray } from '@/lib/Util';
import { observable, action, computed, makeObservable } from 'mobx';
import QuestionSchema, {
  QuestionOptionSchema,
  QuestionAnswerSchema,
  AnswerSchema,
  OptionSchema,
} from 'Schemas/QuestionSchema';
import { QuestionProps } from './Question';

export interface OptionProps extends OptionSchema {
  checked?: boolean;
  manualResponse?: string;
}
export interface ChoiceModelProps {
  options: Array<OptionProps>;
  strAnswer: string;
  changeOption: (index: number) => void;
  reset: () => void;
  selectedOptions: Array<OptionProps>;
  selectedOptionCount: number;
  answer: AnswerSchema[];
  answerOptions?: {
    minSelection: number;
    maxSelection: number;
  };
  isAnswerValid: boolean;
  resetAnswer: () => void;
  setStrAnswer: (str: string) => void;
  hasEtc: boolean;
  hasNone: boolean;
  maxCountSelected: boolean;
  displayOptions: QuestionOptionSchema['displayOptions'];
  pipingType: QuestionSchema['pipingType'];
  pipingQuestionNo: QuestionSchema['pipingQuestionNo'];
  isPipingQuestion: boolean;
  realOptions: OptionProps[];
  setRealOptions: (options: OptionProps[]) => void;
  realMaxSelection: number;
  realMinSelection: number;
  questionNo: QuestionSchema['questionNo'];
}

class ChoiceModel implements ChoiceModelProps {
  // 단일, 복수, 순위
  options = [] as Array<OptionProps>;

  type = 0;

  answerOptions;

  selectedOptions = [] as Array<OptionProps>;

  strAnswer = '';

  displayOptions: QuestionOptionSchema['displayOptions'];

  pipingType: QuestionSchema['pipingType'];

  pipingQuestionNo: QuestionSchema['pipingQuestionNo'];

  realOptions: OptionProps[] = [];

  questionNo: QuestionSchema['questionNo'];

  constructor(question: QuestionSchema, questionAnswer?: QuestionAnswerSchema) {
    const { type, pipingQuestionNo, pipingType, questionNo } = question; // 단일, 복수, 순위
    const { options, displayOptions } = question as QuestionOptionSchema;
    this.answerOptions = question.answerOptions?.multipleChoiceAnswerOptions as {
      minSelection: number;
      maxSelection: number;
    };
    this.type = type;
    this.pipingType = pipingType;
    this.pipingQuestionNo = pipingQuestionNo;
    this.questionNo = questionNo;

    const sortedOptions: OptionProps[] = [];
    let optionNone;
    let optionEtc;

    options?.forEach((option) => {
      const { rank } = option;
      if (rank === RANK_OTHERS) {
        optionEtc = option;
      } else if (rank === RANK_NONE) {
        optionNone = option;
      } else {
        sortedOptions.push(option);
      }
    });

    if (optionEtc) {
      sortedOptions.push(optionEtc);
    }

    if (optionNone) {
      sortedOptions.push(optionNone);
    }

    this.options = sortedOptions;

    this.displayOptions = displayOptions;

    if (questionAnswer) {
      const { answer } = questionAnswer;
      if (answer && answer.length > 0) {
        this.selectedOptions =
          options?.filter((option) => answer.findIndex((answer) => answer.num === option.rank) >= 0) || [];

        const etcAnswer = answer.find((v) => v.str)?.str || '';
        this.setStrAnswer(etcAnswer);
      }
    }

    makeObservable(this, {
      options: observable.ref,
      type: observable,
      selectedOptions: observable.ref,
      strAnswer: observable,
      realOptions: observable,
      changeOption: action,
      reset: action,
      resetAnswer: action,
      setStrAnswer: action,
      setRealOptions: action,
      isAnswerValid: computed,
      selectedOptionCount: computed,
      answer: computed,
      hasEtc: computed,
      hasNone: computed,
      maxCountSelected: computed,
      realMinSelection: computed,
      realMaxSelection: computed,
    });
  }

  setRealOptions = (options: OptionProps[]) => {
    this.realOptions = options;
  };

  setStrAnswer = (str: string): void => {
    this.strAnswer = str;
  };

  resetAnswer = (): void => {
    this.selectedOptions = [];
    this.strAnswer = '';
  };

  changeOption = (no: number): void => {
    const { selectedOptions, selectedOptionCount, hasNone, type, realMaxSelection } = this;
    const selectedOption = this.options.find((v) => v.optionNo === no);
    if (!selectedOption) {
      return;
    }
    const { rank } = selectedOption;
    const findIndex = selectedOptions.findIndex((option) => option.optionNo === no);

    if (hasNone || type === QUESTION_TYPE.SINGLE_CHOICE || rank === RANK_NONE) {
      this.selectedOptions = [selectedOption];

      return;
    }

    const clone = selectedOptions.slice();
    if (findIndex !== undefined && findIndex >= 0) {
      clone.splice(findIndex, 1);
    } else {
      if (selectedOptionCount < realMaxSelection) {
        clone.push(selectedOption);
      }
    }
    this.selectedOptions = clone;
  };

  reset = (): void => {
    this.options = this.options.map((option) => {
      return {
        ...option,
        checked: false,
      };
    });
  };

  get selectedOptionCount(): number {
    return this.selectedOptions.length;
  }

  get isAnswerValid(): boolean {
    const {
      realMaxSelection: maxSelection,
      realMinSelection: minSelection,
      selectedOptionCount,
      hasNone,
      pipingQuestionNo,
      questionNo,
    } = this;

    if (hasNone) {
      return true;
    }

    const isValidEtcOption = this.selectedOptions.some((option) => option.rank === RANK_OTHERS)
      ? this.strAnswer.trim().length > 0
      : true;

    const isValidRange =
      minSelection && maxSelection
        ? minSelection <= selectedOptionCount && selectedOptionCount <= maxSelection
        : selectedOptionCount > 0;

    // 파이핑 문항이고 참조된 기타보기를 선택한 경우,
    if (pipingQuestionNo) {
      const selectedPipedEtcOption = this.selectedOptions.some(
        (option) => option.rank === RANK_OTHERS && option.questionNo !== questionNo
      );

      return selectedPipedEtcOption ? isValidRange : isValidEtcOption && isValidRange;
    }

    return isValidEtcOption && isValidRange;
  }

  get realMaxSelection(): number {
    const { realOptions, answerOptions } = this;
    const { maxSelection: originMaxSelection } = answerOptions;

    const hasNoneOption = realOptions.some((option) => option.rank === RANK_NONE);
    const maxSelection = Math.min(originMaxSelection || Infinity, realOptions.length - Number(hasNoneOption));

    return maxSelection;
  }

  get realMinSelection(): number {
    const { realMaxSelection, answerOptions } = this;
    const { minSelection: originMinSelection } = answerOptions;

    const minSelection = Math.min(originMinSelection || Infinity, realMaxSelection);
    return minSelection;
  }

  get answer(): { num: number; str?: string }[] {
    // TODO: 단일, 복수, 순위 구분 필요
    return this.selectedOptions.map((option) => {
      if (option.rank === RANK_OTHERS) {
        return { num: Number(option.rank), str: this.strAnswer.trim() };
      }
      return { num: Number(option.rank) };
    });
  }

  get hasEtc(): boolean {
    const { selectedOptions } = this;
    return selectedOptions.some((selectedOption) => selectedOption.rank === RANK_OTHERS);
  }

  get hasNone(): boolean {
    const { selectedOptions } = this;
    return selectedOptions.some((selectedOption) => selectedOption.rank === RANK_NONE);
  }

  get maxCountSelected(): boolean {
    return this.selectedOptionCount >= (this.realMaxSelection || 1);
  }

  get isPipingQuestion(): boolean {
    const { pipingQuestionNo } = this;
    return pipingQuestionNo !== null;
  }
}

export default ChoiceModel;

export const getRealOptionList = ({ questions, question }: { questions: QuestionProps[]; question: QuestionProps }) => {
  const concreteQuestion = question.concreteQuestion as ChoiceModelProps;
  const { pipingQuestionNo, displayOptions } = concreteQuestion;
  const isRandomOrder = displayOptions?.choiceAnswerDisplayOptions?.choiceAnswerOrder === 'RANDOM';
  const visibleOptions = concreteQuestion.options.filter((option) => !option.isHidden);

  // 파이핑 문항의 경우
  if (pipingQuestionNo) {
    const { pipingType } = concreteQuestion;
    const refQuestion = questions.find((q) => q.questionNo === pipingQuestionNo);
    const answer = refQuestion?.concreteQuestion.answer;
    const prevRealOptions = (refQuestion?.concreteQuestion as ChoiceModelProps).realOptions;
    const answerMap = new Map(answer?.map((v) => [v.num, v] as const));

    const pipingOptions = visibleOptions.filter((option) => {
      const isPipingOption = option.questionNo !== question.questionNo;
      const isDirectlyPiped = prevRealOptions.some((prevOption) => prevOption.optionNo === option.optionNo);

      // 중첩 파이핑 핸들
      // 기준문항에 있던 옵션이 아니고
      // 나의 옵션이 아니면 제거
      if (!isDirectlyPiped && isPipingOption) return false;

      if (isPipingOption) {
        const isSelected = answerMap.has(option.rank);

        return pipingType === 'INCLUSIVE' ? isSelected : !isSelected;
      }

      return true;
    });

    // 기타보기 응답참조
    const pipedEtcOption = pipingOptions.find((option) => {
      return option.rank === RANK_OTHERS && option.questionNo !== question.questionNo;
    });

    const etcAnswer = answerMap.get(pipedEtcOption?.rank);
    if (pipedEtcOption && etcAnswer?.str) {
      pipedEtcOption.text = etcAnswer.str;
      concreteQuestion.setStrAnswer(pipedEtcOption.text);
    }

    return isRandomOrder ? shuffleOptions(pipingOptions) : pipingOptions;
  }

  return isRandomOrder ? shuffleOptions(visibleOptions) : visibleOptions;
};

const shuffleOptions = (options: OptionProps[]) => {
  const etcOrNoneOptionCount = options.reduce((count, { rank }) => {
    const notNormalOption = rank === RANK_OTHERS || rank === RANK_NONE;
    return count + (notNormalOption ? 1 : 0);
  }, 0);

  if (etcOrNoneOptionCount === 0) {
    return shuffleArray(options);
  }

  const normalOptions = options.slice(0, options.length - etcOrNoneOptionCount);
  const notNormalOptions = options.slice(-etcOrNoneOptionCount);
  return [...shuffleArray(normalOptions), ...notNormalOptions];
};
